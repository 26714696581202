<template>
    <div class="wrapper">
        <NavBar />
        <Menu />
        <!-- Spinner Start -->
        <div id="spinner" v-if="halamanloading"
            class="show bg-white position-fixed translate-middle w-100 vh-100 top-50 start-50 d-flex align-items-center justify-content-center">
            <div class="spinner-border text-danger" style="width: 3rem; height: 3rem;" role="status">
                <span class="sr-only">Loading...</span>
            </div>
        </div>
        <!-- Spinner End -->
        <div class="content-wrapper py-3">
            <div class="content-header">
                <div class="container-fluid">
                    <div class="row mb-2">
                        <div class="col-sm-6 wow fadeInDown">
                            <h5>P3A</h5>
                        </div>
                        <div class="col-sm-6 wow fadeInDown">
                            <ol class="breadcrumb float-sm-right">
                                <li class="breadcrumb-item"><a href="/Dashboard">Home </a></li>
                                <li class="breadcrumb-item active">Pengaturan Data P3A</li>
                            </ol>
                        </div>
                    </div><!-- /.row -->
                </div><!-- /.container-fluid -->
            </div>
            <!-- /.content-header -->
            <div class="content">
                <div class="container-fluid">
                    <div class="row">
                        <div class="col-sm-12 wow fadeInDown">
                            <div class="card cardputih">
                                <div class="card-header border-transparent">
                                    <div class="card-tools">
                                        <button type="button" class="btn btn-tool" data-card-widget="collapse">
                                            <i class="fas fa-minus"></i>
                                        </button>
                                    </div>
                                </div>
                                <div class="card-body p-0">
                                    <div class="col-sm-12 text-center p-2">
                                        <button type="button" class="btn btn-danger3 btn-sm text-white lebar2"
                                            @click="prosesdata('Tambah')" data-toggle="tooltip" data-placement="left"
                                            title="Tambah data..">
                                            <i class="fas fa-plus"></i> Tambah
                                        </button>
                                    </div>
                                    <div class="card card-warning collapsed-card">
                                        <div class="card-header" data-card-widget="collapse" style="cursor: pointer;">
                                            <h5 class="card-title">Filter Data</h5>

                                            <div class="card-tools">
                                                <button type="button" class="btn btn-tool" data-card-widget="collapse">
                                                    <i class="fas fa-plus" style="color: #ffffff;"></i>
                                                </button>
                                            </div>
                                        </div>
                                        <!-- /.card-header -->
                                        <div class="card-body">
                                            <div class="row">
                                                <div class="col-sm-4">
                                                    <label>Pilihan Kewenangan </label>
                                                    <v-select :options="kewenangan" :reduce="(label) => label.code"
                                                        label="label" v-model="defaultSelectedkewenangan"
                                                        @update:modelValue="onkewenanganChange"
                                                        style="background-color: #ffffff;"></v-select>
                                                </div>
                                                <div class="col-sm-4">
                                                    <label>Pilihan Daerah Irigasi </label>
                                                    <v-select :options="kodeDI" :reduce="(label) => label.code"
                                                        label="label" v-model="defaultSelectedkodeDI"
                                                        @update:modelValue="onkodeDIChange"
                                                        style="background-color: #ffffff;"></v-select>
                                                </div>
                                                <div class="col-sm-4">
                                                    <label>Pilihan Balai </label>
                                                    <v-select :options="balai" :reduce="(label) => label.code"
                                                        label="label" v-model="defaultSelectedbalai"
                                                        @update:modelValue="onbalaiChange"
                                                        style="background-color: #ffffff;"></v-select>
                                                </div>
                                            </div>
                                            <!-- /.row -->
                                        </div>
                                        <!-- ./card-body -->
                                    </div>
                                    <div class="col-sm-12 float-left pb-2 px-3">
                                        <div class="card collapsed-card">
                                            <div class="card-header" data-card-widget="collapse"
                                                style="cursor: pointer;">
                                                <h3 class="card-title">Show / Hide Kolom</h3>
                                                <div class="card-tools">
                                                    <button type="button" class="btn btn-tool"
                                                        data-card-widget="collapse"><i class="fas fa-plus"
                                                            style="color: #ffffff;"></i>
                                                    </button>
                                                </div>
                                            </div>
                                            <div class="card-body">
                                                <div class="card">
                                                    <div class="card-body">
                                                        <div class="row">
                                                            <div class="col-lg-2 col-12">
                                                                <div class="custom-control custom-checkbox">
                                                                    <input
                                                                        class="custom-control-input custom-control-input-warning"
                                                                        type="checkbox" id="customCheckboxKode"
                                                                        value="optionKode" @click="showkode()">
                                                                    <label for="customCheckboxKode"
                                                                        class="custom-control-label">
                                                                        Kode D.I.</label>
                                                                </div>
                                                            </div>
                                                            <div class="col-lg-2 col-12">
                                                                <div class="custom-control custom-checkbox">
                                                                    <input
                                                                        class="custom-control-input custom-control-input-warning"
                                                                        type="checkbox" id="customCheckboxNamaDI"
                                                                        value="optionNamaDI" @click="showNamaDI()">
                                                                    <label for="customCheckboxNamaDI"
                                                                        class="custom-control-label">
                                                                        Nama D.I.</label>
                                                                </div>
                                                            </div>
                                                            <div class="col-lg-2 col-12">
                                                                <div class="custom-control custom-checkbox">
                                                                    <input
                                                                        class="custom-control-input custom-control-input-warning"
                                                                        type="checkbox" id="customCheckboxLuas"
                                                                        value="optionLuas" @click="showluas()">
                                                                    <label for="customCheckboxLuas"
                                                                        class="custom-control-label">
                                                                        Luas</label>
                                                                </div>
                                                            </div>
                                                            <div class="col-lg-2 col-12">
                                                                <div class="custom-control custom-checkbox">
                                                                    <input
                                                                        class="custom-control-input custom-control-input-warning"
                                                                        type="checkbox" id="customCheckboxBalai"
                                                                        value="optionBalai" @click="showbalai()">
                                                                    <label for="customCheckboxBalai"
                                                                        class="custom-control-label">
                                                                        Balai</label>
                                                                </div>
                                                            </div>
                                                            <div class="col-lg-2 col-12">
                                                                <div class="custom-control custom-checkbox">
                                                                    <input
                                                                        class="custom-control-input custom-control-input-warning"
                                                                        type="checkbox" id="customCheckboxKewenangan"
                                                                        value="optionKewenangan"
                                                                        @click="showkewenangan()">
                                                                    <label for="customCheckboxKewenangan"
                                                                        class="custom-control-label">
                                                                        Kewenangan</label>
                                                                </div>
                                                            </div>
                                                            <div class="col-lg-2 col-12">
                                                                <div class="custom-control custom-checkbox">
                                                                    <input
                                                                        class="custom-control-input custom-control-input-warning"
                                                                        type="checkbox" id="customCheckboxIP3A"
                                                                        value="optionIP3A" @click="showIP3A()">
                                                                    <label for="customCheckboxIP3A"
                                                                        class="custom-control-label">
                                                                        IP3A</label>
                                                                </div>
                                                            </div>
                                                            <div class="col-lg-2 col-12">
                                                                <div class="custom-control custom-checkbox">
                                                                    <input
                                                                        class="custom-control-input custom-control-input-warning"
                                                                        type="checkbox" id="customCheckboxGP3A"
                                                                        value="optionGP3A" @click="showGP3A()">
                                                                    <label for="customCheckboxGP3A"
                                                                        class="custom-control-label">
                                                                        GP3A</label>
                                                                </div>
                                                            </div>
                                                            <div class="col-lg-2 col-12">
                                                                <div class="custom-control custom-checkbox">
                                                                    <input
                                                                        class="custom-control-input custom-control-input-warning"
                                                                        type="checkbox" id="customCheckboxTahun"
                                                                        value="optionTahun" @click="showtahun()">
                                                                    <label for="customCheckboxTahun"
                                                                        class="custom-control-label">
                                                                        Tahun</label>
                                                                </div>
                                                            </div>
                                                            <div class="col-lg-2 col-12">
                                                                <div class="custom-control custom-checkbox">
                                                                    <input
                                                                        class="custom-control-input custom-control-input-warning"
                                                                        type="checkbox" id="customCheckboxWilayah"
                                                                        value="optionWilayah"
                                                                        @click="showindukwilayah()">
                                                                    <label for="customCheckboxWilayah"
                                                                        class="custom-control-label">
                                                                        Nomor Induk Wilayah</label>
                                                                </div>
                                                            </div>
                                                            <div class="col-lg-2 col-12">
                                                                <div class="custom-control custom-checkbox">
                                                                    <input
                                                                        class="custom-control-input custom-control-input-warning"
                                                                        type="checkbox" id="customCheckboxIrigasi"
                                                                        value="optionIrigasi"
                                                                        @click="showindukirigasi()">
                                                                    <label for="customCheckboxIrigasi"
                                                                        class="custom-control-label">
                                                                        Nomor Induk Irigasi</label>
                                                                </div>
                                                            </div>
                                                            <div class="col-lg-2 col-12">
                                                                <div class="custom-control custom-checkbox">
                                                                    <input
                                                                        class="custom-control-input custom-control-input-warning"
                                                                        type="checkbox" id="customCheckboxAkta"
                                                                        value="optionAkta" @click="showakta()">
                                                                    <label for="customCheckboxAkta"
                                                                        class="custom-control-label">
                                                                        Akta</label>
                                                                </div>
                                                            </div>
                                                            <div class="col-lg-2 col-12">
                                                                <div class="custom-control custom-checkbox">
                                                                    <input
                                                                        class="custom-control-input custom-control-input-warning"
                                                                        type="checkbox" id="customCheckboxSK"
                                                                        value="optionSK" @click="showsk()">
                                                                    <label for="customCheckboxSK"
                                                                        class="custom-control-label">
                                                                        SK</label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-sm-12 float-left pb-2 px-3 wow fadeInDown">
                                        Jumlah Data: {{ G_numFormat(jumlahdata) }}
                                        <span style="float: right;">
                                            Halaman ke: <select v-model="halaman" @change="onChangeHalaman($event)">
                                                <option v-for="index in jumlahhalaman" :key="index">
                                                    {{ index }}</option>
                                            </select> dari {{ jumlahhalaman }}
                                        </span>
                                    </div>
                                    <div class="col-sm-12 float-left pb-2 wow fadeInDown">
                                        <input type="text" v-model="carikata" class="form-control form-control-sm"
                                            placeholder="Masukkan Kata Kunci Pencarian Halaman Terpilih">
                                    </div>
                                    <div class="table-responsive table-wrapper">
                                        <table class="table m-0" id="tabeldata">
                                            <thead>
                                                <tr>
                                                    <th style="width: 50px;" rowspan="3">No</th>
                                                    <th style="width: 50px;" rowspan="3">Opsi</th>
                                                    <th style="width: 50px;" rowspan="3" v-if="showColumnKode">Kode</th>
                                                    <th style="width: 50px;" rowspan="3" v-if="showColumnNamaDI">Daerah
                                                        Irigasi</th>
                                                    <th style="width: 60px;" rowspan="3" v-if="showColumnLuas">
                                                        Luas<br>(Ha)</th>
                                                    <th style="width: 100px;" rowspan="3" v-if="showColumnBalai">
                                                        Nama<br>Balai</th>
                                                    <th style="width: 100px;" rowspan="3" v-if="showColumnKewenangan">
                                                        Kewenangan</th>
                                                    <th style="width: 100px;" rowspan="3" v-if="showColumnIP3A">
                                                        Nama<br>IP3A</th>
                                                    <th style="width: 100px;" rowspan="3" v-if="showColumnGP3A">
                                                        Nama<br><I></I>GP3A</th>
                                                    <th style="width: 100px;" rowspan="3">Nama<br>P3A</th>
                                                    <th style="width: 50px;" rowspan="3" v-if="showColumnTahun">Tahun
                                                    </th>
                                                    <th style="width: 50px;" rowspan="3">Nilai<br>Evaluasi<br>Kinerja
                                                    </th>
                                                    <th style="width: 50px;" rowspan="3">Kategori<br>Evaluasi<br>Kinerja
                                                    </th>
                                                    <th style="width: 100px;" rowspan="3">Revitalisasi<br>P3A</th>
                                                    <th style="width: 100px;" rowspan="3" v-if="showColumnIndukWilayah">
                                                        Nomor
                                                        Induk<br>Wilayah<br>P3A</th>
                                                    <th style="width: 100px;" rowspan="3" v-if="showColumnIndukIrigasi">
                                                        Nomor
                                                        Induk<br>Irigasi<br>P3A</th>
                                                    <th style="width: 100px;" rowspan="3" v-if="showColumnAkta">
                                                        Akta<br>P3A</th>
                                                    <th style="width: 100px;" rowspan="3" v-if="showColumnSK">SK<br>P3A
                                                    </th>
                                                    <th style="width: 50px;" rowspan="3">Luas Baku<br>Petak
                                                        Tersier<br>(Ha)</th>
                                                    <th style="width: 80px;" colspan="7">Kelembagaan</th>
                                                </tr>
                                                <tr>
                                                    <th style="width: 80px;" rowspan="2">Ketua</th>
                                                    <th style="width: 80px;" colspan="3">Pengurus</th>
                                                    <th style="width: 80px;" rowspan="2">Jumlah<br>Anggota</th>
                                                    <th style="width: 80px;" rowspan="2">TPM</th>
                                                </tr>
                                                <tr>
                                                    <th style="width: 80px;">Laki - Laki</th>
                                                    <th style="width: 80px;">Perempuan</th>
                                                    <th style="width: 80px;">Total</th>
                                                </tr>
                                                <tr>
                                                    <td class="levelsubkegiatan" :colspan="colspan1"> TOTAL</td>
                                                    <td class="levelsubkegiatan text-center">
                                                        {{ G_numFormat(TotalLaki2PengurusP3A) }}
                                                    </td>
                                                    <td class="levelsubkegiatan text-center">
                                                        {{ G_numFormat(TotalPerempuanPengurusP3A) }}
                                                    </td>
                                                    <td class="levelsubkegiatan text-center">
                                                        {{ G_numFormat(TotalJmlPengurusP3A) }}
                                                    </td>
                                                    <td class="levelsubkegiatan text-center">
                                                        {{ G_numFormat(TotalJmlAnggotaP3A) }}
                                                    </td>
                                                    <td class="levelsubkegiatan text-center">
                                                        {{ G_numFormat(TotalJmlTPM) }}
                                                    </td>
                                                </tr>
                                            </thead>
                                            <template v-for="(datalist, urutlist) in filteredKataData" :key="urutlist">
                                                <tbody>
                                                    <tr>
                                                        <td class="text-center">{{ urutlist + 1 }}</td>
                                                        <td :class="datalist.class">
                                                            <button type="button"
                                                                class="btn btn-info btn-sm text-white"
                                                                @click="mappingdata(datalist.id)" data-toggle="tooltip"
                                                                data-placement="left" title="Mapping P3A.."><i
                                                                    class="fas fa-edit"></i> Mapping P3A</button>
                                                        </td>
                                                        <td v-if="showColumnKode">
                                                            {{ datalist.KodeDI }}
                                                        </td>
                                                        <td v-if="showColumnNamaDI">
                                                            {{ datalist.NamaDI }}
                                                        </td>
                                                        <td class="text-right" v-if="showColumnLuas">
                                                            {{ G_numFormatKoma(datalist.LuasHa) }}
                                                        </td>
                                                        <td v-if="showColumnBalai">
                                                            {{ datalist.NmBalai }}
                                                        </td>
                                                        <td v-if="showColumnKewenangan">
                                                            {{ datalist.NamaKewenangan }}
                                                        </td>
                                                        <td v-if="showColumnIP3A">
                                                            {{ datalist.NamaIP3A }}
                                                        </td>
                                                        <td v-if="showColumnGP3A">
                                                            {{ datalist.NamaGP3A }}
                                                        </td>
                                                        <td>
                                                            {{ datalist.NamaP3A }}
                                                        </td>
                                                        <td v-if="showColumnTahun">
                                                            {{ datalist.TahunP3A }}
                                                        </td>
                                                        <td class="text-center">
                                                            {{ G_numFormatKoma(datalist.NilaiEvkin) }}
                                                        </td>
                                                        <td class="text-center">
                                                            {{ datalist.KodeKlasifikasiEvkin }}
                                                        </td>
                                                        <td>
                                                            {{ datalist.RevitalisasiP3A }}
                                                        </td>
                                                        <td v-if="showColumnIndukWilayah">
                                                            {{ datalist.NomorIndukWilayahP3A }}
                                                        </td>
                                                        <td v-if="showColumnIndukIrigasi">
                                                            {{ datalist.NomorIndukIrigasiP3A }}
                                                        </td>
                                                        <td v-if="showColumnAkta">
                                                            {{ datalist.NoAktaP3A }}
                                                        </td>
                                                        <td v-if="showColumnSK">
                                                            {{ datalist.NoSKP3A }}
                                                        </td>
                                                        <td class="text-right">
                                                            {{ G_numFormatKoma(datalist.LuasBakuSawahHa) }}
                                                        </td>
                                                        <td>
                                                            {{ datalist.NamaKetuaP3A }}
                                                        </td>
                                                        <td class="text-center">
                                                            {{ G_numFormat(datalist.Laki2PengurusP3A) }}
                                                        </td>
                                                        <td class="text-center">
                                                            {{ G_numFormat(datalist.PerempuanPengurusP3A) }}
                                                        </td>
                                                        <td class="text-center">
                                                            {{ G_numFormat(datalist.JmlPengurusP3A) }}
                                                        </td>
                                                        <td class="text-center">
                                                            {{ G_numFormat(datalist.JmlAnggotaP3A) }}
                                                        </td>
                                                        <td>
                                                            {{ datalist.NamaTPM }}
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </template>
                                        </table>
                                    </div>
                                </div>
                                <div class="card-footer p-3">

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <Footer />
    </div>
</template>

<script>
import $ from 'jquery';
import axios from 'axios';
import vSelect from 'vue-select';
import swal from 'sweetalert2';
import CryptoJS from 'crypto-js';
import Footer from "../../componen/Footer.vue";
import NavBar from "../../componen/NavBar.vue";
import Menu from "../../componen/Menu.vue";

import VueApexCharts from "vue3-apexcharts";
window.Apex.chart = { fontFamily: "Poppins-SemiBold, Arial, sans-serif" };
export default {
    components: {
        Footer, NavBar, Menu, vSelect, swal
    },
    data() {
        return {
            halamanloading: true,
            iduser: JSON.parse(localStorage.getItem("usistem")),
            leveluser: JSON.parse(localStorage.getItem("lsistem")),
            namauser: JSON.parse(localStorage.getItem("nmusistem")),
            datapesan: '',
            datamaster: [],
            carikata: '',
            klasifikasi: [],
            pberlembaga: 0,
            ptakberlembaga: 0,
            TotalLaki2PengurusP3A: 0,
            TotalPerempuanPengurusP3A: 0,
            TotalJmlPengurusP3A: 0,
            TotalJmlAnggotaP3A: 0,
            TotalJmlTPM: 0,
            jumlahdata: 0,
            jumlahhalaman: 0,
            mulai: 0,
            halaman: 1,
            kewenangan: [],
            datakewenangan: [],
            defaultSelectedkewenangan: {
                code: 0,
                label: 'Semua Data',
                tag: '',
            },
            selectedkewenangan: '',
            kodeDI: [],
            datakodeDI: [],
            defaultSelectedkodeDI: {
                code: 0,
                label: 'Semua Data',
                tag: '',
            },
            selectedkodeDI: '',
            balai: [],
            databalai: [],
            defaultSelectedbalai: {
                code: 0,
                label: 'Semua Data',
                tag: '',
            },
            selectedbalai: '',
            totalsaluran: 0,
            totalbangunan: 0,
            totalpetak: 0,
            showColumnKode: false,
            showColumnLuas: false,
            showColumnBalai: false,
            showColumnKewenangan: false,
            showColumnNamaDI: false,
            showColumnAkta: false,
            showColumnSK: false,
            showColumnIP3A: false,
            showColumnGP3A: false,
            showColumnIndukWilayah: false,
            showColumnIndukIrigasi: false,
            showColumnTahun: false,
            colspan1: 8,
            series: [],
            chartOptions: {
                colors: [
                    '#f9a867',
                    '#6a6ba5',
                    '#6c98b7',
                    '#2a6b9b',
                    '#d5e2eb',
                ],
                chart: {
                    type: 'bar',
                    height: 350,
                    stacked: true,
                    toolbar: {
                        show: true
                    },
                    zoom: {
                        enabled: true
                    }
                },
                responsive: [{
                    breakpoint: 480,
                    options: {
                        legend: {
                            position: 'bottom',
                            offsetX: -10,
                            offsetY: 0
                        }
                    }
                }],
                plotOptions: {
                    bar: {
                        horizontal: false,
                        borderRadius: 10,
                        borderRadiusApplication: 'end', // 'around', 'end'
                        borderRadiusWhenStacked: 'last', // 'all', 'last'
                        dataLabels: {
                            total: {
                                enabled: true,
                                style: {
                                    fontSize: '13px',
                                    fontWeight: 900
                                }
                            }
                        }
                    },
                },
                xaxis: {
                    type: 'text',
                    categories: ['P3A'],
                },
                legend: {
                    position: 'right',
                    offsetY: 40
                },
                fill: {
                    opacity: 1
                }
            },
        }
    },
    computed: {
        filteredKataData() {
            const filteredWorkers = this.carikata === ""
                ? this.datamaster
                : this.datamaster.filter(wo => Object.values(wo).join("").toLowerCase().indexOf(this.carikata.toLowerCase()) !== -1);
            return filteredWorkers;
        },
        JumlahfilteredKataData() {
            var jumlah = 0;
            try {
                if (this.filteredKataData === undefined) {
                    jumlah = 0;
                } else {
                    jumlah = this.filteredKataData.length;
                }
            }
            catch {
                jumlah = 0;
            }
            return jumlah;
        },
    },
    methods: {
        getOtentifikasi() {
            var time = Date.parse(localStorage.getItem("tsistem"));
            var timeakhir = time + (3 * 60 * 60 * 1000);
            var timesekarang = Date.parse(new Date());
            if (timesekarang > timeakhir) {
                this.ClearlocalStorage();
            }
        },
        showtahun() {
            this.showColumnTahun = !this.showColumnTahun;
            if (this.showColumnTahun == true) {
                this.colspan1 = this.colspan1 + 1;
            }
            else {
                this.colspan1 = this.colspan1 - 1;
            }
        },
        showkode() {
            this.showColumnKode = !this.showColumnKode;
            if (this.showColumnKode == true) {
                this.colspan1 = this.colspan1 + 1;
            }
            else {
                this.colspan1 = this.colspan1 - 1;
            }
        },
        showluas() {
            this.showColumnLuas = !this.showColumnLuas;
            if (this.showColumnLuas == true) {
                this.colspan1 = this.colspan1 + 1;
            }
            else {
                this.colspan1 = this.colspan1 - 1;
            }
        },
        showindukwilayah() {
            this.showColumnIndukWilayah = !this.showColumnIndukWilayah;
            if (this.showColumnIndukWilayah == true) {
                this.colspan1 = this.colspan1 + 1;
            }
            else {
                this.colspan1 = this.colspan1 - 1;
            }
        },
        showindukirigasi() {
            this.showColumnIndukIrigasi = !this.showColumnIndukIrigasi;
            if (this.showColumnIndukIrigasi == true) {
                this.colspan1 = this.colspan1 + 1;
            }
            else {
                this.colspan1 = this.colspan1 - 1;
            }
        },
        showakta() {
            this.showColumnAkta = !this.showColumnAkta;
            if (this.showColumnAkta == true) {
                this.colspan1 = this.colspan1 + 1;
            }
            else {
                this.colspan1 = this.colspan1 - 1;
            }
        },
        showsk() {
            this.showColumnSK = !this.showColumnSK;
            if (this.showColumnSK == true) {
                this.colspan1 = this.colspan1 + 1;
            }
            else {
                this.colspan1 = this.colspan1 - 1;
            }
        },
        showbalai() {
            this.showColumnBalai = !this.showColumnBalai;
            if (this.showColumnBalai == true) {
                this.colspan1 = this.colspan1 + 1;
            }
            else {
                this.colspan1 = this.colspan1 - 1;
            }
        },
        showkewenangan() {
            this.showColumnKewenangan = !this.showColumnKewenangan;
            if (this.showColumnKewenangan == true) {
                this.colspan1 = this.colspan1 + 1;
            }
            else {
                this.colspan1 = this.colspan1 - 1;
            }
        },
        showNamaDI() {
            this.showColumnNamaDI = !this.showColumnNamaDI;
            if (this.showColumnNamaDI == true) {
                this.colspan1 = this.colspan1 + 1;
            }
            else {
                this.colspan1 = this.colspan1 - 1;
            }
        },
        showIP3A() {
            this.showColumnIP3A = !this.showColumnIP3A;
            if (this.showColumnIP3A == true) {
                this.colspan1 = this.colspan1 + 1;
            }
            else {
                this.colspan1 = this.colspan1 - 1;
            }
        },
        showGP3A() {
            this.showColumnGP3A = !this.showColumnGP3A;
            if (this.showColumnGP3A == true) {
                this.colspan1 = this.colspan1 + 1;
            }
            else {
                this.colspan1 = this.colspan1 - 1;
            }
        },
        async ambildataReffPublik() {
            this.halamanloading = true;
            const mainAPIReFF = axios.create({
                baseURL: process.env.VUE_APP_URL_API,
                headers: {
                    Authorization: "Bearer GPMop8LQ06S0rZXcJyEH3wk8jVrINbHwn7tBq2",
                },
            });
            var random = Math.random();
            await mainAPIReFF.get("parastapainnovation_p-GetKewenangan?random=" + random).then(
                Response => {
                    this.datapesan = Response.data.message;
                    if (this.datapesan == 'data diketemukan') {
                        this.datakewenangan = [];
                        this.kewenangan = [];
                        this.datakewenangan = Response.data.content.data;
                        this.datakewenangan.forEach((item) => {
                            this.kewenangan.push({
                                label: item.nama,
                                code: this.datakewenangan.indexOf(item) + 1,
                                tag: item.id,
                            });
                        });
                        this.kewenangan.unshift({
                            label: 'Semua Data',
                            code: 0,
                            tag: '',
                        });
                    }
                    else {
                        this.datakewenangan = [];
                        this.kewenangan = [];
                        this.kewenangan.unshift({
                            label: 'Semua Data',
                            code: 0,
                            tag: '',
                        });
                    }
                    this.halamanloading = false;
                }
            ).catch(error => {
                swal.fire('Peringatan', 'Kesalahan fecthing data', 'error');
                this.halamanloading = false;
                return false;
            })
            await mainAPIReFF.get("parastapainnovation_p-GetDI?random=" + random + "&kewenangan=" + this.selectedkewenangan).then(
                Response => {
                    this.datapesan = Response.data.message;
                    if (this.datapesan == 'data diketemukan') {
                        this.datakodeDI = [];
                        this.kodeDI = [];
                        this.datakodeDI = Response.data.content.data;
                        this.datakodeDI.forEach((item) => {
                            this.kodeDI.push({
                                label: '[' + item.kode + '] ' + item.nama,
                                code: this.datakodeDI.indexOf(item) + 1,
                                tag: item.kode,
                            });
                        });
                        this.kodeDI.unshift({
                            label: 'Semua Data',
                            code: 0,
                            tag: '',
                        });
                    }
                    else {
                        this.datakodeDI = [];
                        this.kodeDI = [];
                        this.kodeDI.unshift({
                            label: 'Semua Data',
                            code: 0,
                            tag: '',
                        });
                    }
                    this.halamanloading = false;
                }
            ).catch(error => {
                swal.fire('Peringatan', 'Kesalahan fecthing data', 'error');
                this.halamanloading = false;
                return false;
            })
            await mainAPIReFF.get("parastapainnovation_p-GetBalai?random=" + random + "&kewenangan=" + this.selectedkewenangan).then(
                Response => {
                    this.datapesan = Response.data.message;
                    if (this.datapesan == 'data diketemukan') {
                        this.databalai = [];
                        this.balai = [];
                        this.databalai = Response.data.content.data;
                        this.databalai.forEach((item) => {
                            this.balai.push({
                                label: item.nama,
                                code: this.databalai.indexOf(item) + 1,
                                tag: item.id,
                            });
                        });
                        this.balai.unshift({
                            label: 'Semua Data',
                            code: 0,
                            tag: '',
                        });
                    }
                    else {
                        this.databalai = [];
                        this.balai = [];
                        this.balai.unshift({
                            label: 'Semua Data',
                            code: 0,
                            tag: '',
                        });
                    }
                    this.halamanloading = false;
                }
            ).catch(error => {
                swal.fire('Peringatan', 'Kesalahan fecthing data', 'error');
                this.halamanloading = false;
                return false;
            })
        },
        async filterdataReffPublik() {
            this.halamanloading = true;
            const mainAPIReFF = axios.create({
                baseURL: process.env.VUE_APP_URL_API,
                headers: {
                    Authorization: "Bearer GPMop8LQ06S0rZXcJyEH3wk8jVrINbHwn7tBq2",
                },
            });
            var random = Math.random();
            //alert(this.selectedkewenangan);
            await mainAPIReFF.get("parastapainnovation_p-GetDI?random=" + random + "&kewenangan=" + this.selectedkewenangan).then(
                Response => {
                    this.datapesan = Response.data.message;
                    if (this.datapesan == 'data diketemukan') {
                        this.datakodeDI = [];
                        this.kodeDI = [];
                        this.datakodeDI = Response.data.content.data;
                        this.datakodeDI.forEach((item) => {
                            this.kodeDI.push({
                                label: '[' + item.kode + '] ' + item.nama,
                                code: this.datakodeDI.indexOf(item) + 1,
                                tag: item.kode,
                            });
                        });
                        this.kodeDI.unshift({
                            label: 'Semua Data',
                            code: 0,
                            tag: '',
                        });
                    }
                    else {
                        this.datakodeDI = [];
                        this.kodeDI = [];
                        this.kodeDI.unshift({
                            label: 'Semua Data',
                            code: 0,
                            tag: '',
                        });
                    }
                    this.halamanloading = false;

                }
            ).catch(error => {
                swal.fire('Peringatan', 'Kesalahan fecthing data', 'error');
                this.halamanloading = false;
                return false;
            })
            await mainAPIReFF.get("parastapainnovation_p-GetBalai?random=" + random + "&kewenangan=" + this.selectedkewenangan).then(
                Response => {
                    this.datapesan = Response.data.message;
                    if (this.datapesan == 'data diketemukan') {
                        this.databalai = [];
                        this.balai = [];
                        this.databalai = Response.data.content.data;
                        this.databalai.forEach((item) => {
                            this.balai.push({
                                label: item.nama,
                                code: this.databalai.indexOf(item) + 1,
                                tag: item.id,
                            });
                        });
                        this.balai.unshift({
                            label: 'Semua Data',
                            code: 0,
                            tag: '',
                        });
                    }
                    else {
                        this.databalai = [];
                        this.balai = [];
                        this.balai.unshift({
                            label: 'Semua Data',
                            code: 0,
                            tag: '',
                        });
                    }
                    this.halamanloading = false;
                }
            ).catch(error => {
                swal.fire('Peringatan', 'Kesalahan fecthing data', 'error');
                this.halamanloading = false;
                return false;
            })

            this.pindahhalaman(1);
        },
        onkewenanganChange(a) {
            try {
                this.selectedkewenangan = this.kewenangan[a].tag;
            }
            catch (err) {
                return;
            }
            this.defaultSelectedkodeDI = {
                code: 0,
                label: 'Semua Data',
                tag: '',
            };
            this.selectedkodeDI = '';
            this.defaultSelectedbalai = {
                code: 0,
                label: 'Semua Data',
                tag: '',
            };
            this.selectedbalai = '';
            this.filterdataReffPublik();
        },
        onbalaiChange(a) {
            try {
                this.selectedbalai = this.balai[a].tag;
            }
            catch (err) {
                return;
            }
            this.filterdataReffPublik();
        },
        onkodeDIChange(a) {
            try {
                this.selectedkodeDI = this.kodeDI[a].tag;
            }
            catch (err) {
                return;
            }
            this.filterdataReffPublik();
        },
        onChangeHalaman(event) {
            this.pindahhalaman(event.target.value);
        },
        async pindahhalaman(halamannya) {
            this.halamanloading = true;
            const mainAPI = axios.create({
                baseURL: process.env.VUE_APP_URL_API,
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("tokensistem"),
                },
            });
            //alert(this.selectedkewenangan);
            var random = Math.random();
            await mainAPI.get("parastapainnovationp3a-GetP3A?random=" + random + "&halaman=" + halamannya + "&kodeDI=" + this.selectedkodeDI + "&balai=" + this.selectedbalai + "&kewenangan=" + this.selectedkewenangan).then(
                Response => {

                    this.datapesan = Response.data.message;
                    if (this.datapesan == 'data diketemukan') {
                        this.datamaster = [];
                        this.datamaster = Response.data.content.data;
                        this.pberlembaga = Response.data.resume[0].P3ABerlembaga;
                        this.ptakberlembaga = Response.data.resume[0].P3ATakBerlembaga;
                        this.TotalLaki2PengurusP3A = Response.data.resume[0].Laki2PengurusP3A;
                        this.TotalPerempuanPengurusP3A = Response.data.resume[0].PerempuanPengurusP3A;
                        this.TotalJmlPengurusP3A = Response.data.resume[0].JmlPengurusP3A;
                        this.TotalJmlAnggotaP3A = Response.data.resume[0].JmlAnggotaP3A;
                        this.TotalJmlTPM = Response.data.resume[0].JmlTPM;
                        this.TotalP3A = Response.data.resume[0].JumlahTotalP3A;
                        this.TotalP3Aevkin = Response.data.resume[0].JumlahP3Asudahevkin;
                        this.klasifikasi = Response.data.resume[0].DataTotalKlasifikasiEvkinP3A;
                        var temptabel = Response.data.resume[0].DataTotalKlasifikasiEvkinP3A;
                        //alert(temptabel.length);
                        this.series = [];
                        for (let i = 0; i < temptabel.length; i++) {
                            this.series.push({
                                name: temptabel[i].KodeKlasifikasiEvkin + ' (' + temptabel[i].KlasifikasiEvkin + ')',
                                data: [temptabel[i].JumlahP3A]
                            });
                        }
                        this.jumlahdata = Response.data.content.jumlahdata;
                        this.jumlahhalaman = Response.data.content.jumlahhalaman;
                        this.mulai = Response.data.content.mulai;
                        if (this.mulai == 0) {
                            this.halaman = 1;
                        }
                        else {
                            this.halaman = (this.mulai / 50) + 1;
                        }
                    }
                    else {

                        this.datamaster = [];
                        this.series = [];
                        this.pberlembaga = 0;
                        this.ptakberlembaga = 0;
                        this.TotalLaki2PengurusP3A = 0;
                        this.TotalPerempuanPengurusP3A = 0;
                        this.TotalJmlPengurusP3A = 0;
                        this.TotalJmlAnggotaP3A = 0;
                        this.TotalJmlTPM = 0;
                        this.TotalP3A = 0;
                        this.TotalP3Aevkin = 0;
                        this.klasifikasi = 0;
                        this.jumlahdata = 0;
                        this.jumlahhalaman = 0;
                        this.mulai = 0;
                        this.halaman = 1;
                    }
                    this.halamanloading = false;
                }
            ).catch(error => {
                this.datapesan = [];
                this.datamaster = [];
                this.halamanloading = false;
            });
        },
        async ambildata() {
            this.halamanloading = true;
            const mainAPI = axios.create({
                baseURL: process.env.VUE_APP_URL_API,
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("tokensistem"),
                },
            });
            var random = Math.random();
            await mainAPI.get("parastapainnovationp3a-GetP3A?random=" + random + "&kodeDI=" + this.selectedkodeDI + "&balai=" + this.selectedbalai + "&kewenangan=" + this.selectedkewenangan).then(
                Response => {

                    this.datapesan = Response.data.message;
                    if (this.datapesan == 'data diketemukan') {
                        this.datamaster = [];
                        this.datamaster = Response.data.content.data;
                        this.pberlembaga = Response.data.resume[0].P3ABerlembaga;
                        this.ptakberlembaga = Response.data.resume[0].P3ATakBerlembaga;
                        this.TotalLaki2PengurusP3A = Response.data.resume[0].Laki2PengurusP3A;
                        this.TotalPerempuanPengurusP3A = Response.data.resume[0].PerempuanPengurusP3A;
                        this.TotalJmlPengurusP3A = Response.data.resume[0].JmlPengurusP3A;
                        this.TotalJmlAnggotaP3A = Response.data.resume[0].JmlAnggotaP3A;
                        this.TotalJmlTPM = Response.data.resume[0].JmlTPM;
                        this.TotalP3A = Response.data.resume[0].JumlahTotalP3A;
                        this.TotalP3Aevkin = Response.data.resume[0].JumlahP3Asudahevkin;
                        this.klasifikasi = Response.data.resume[0].DataTotalKlasifikasiEvkinP3A;
                        var temptabel = Response.data.resume[0].DataTotalKlasifikasiEvkinP3A;
                        //alert(JSON.stringify(temptabel));
                        //alert(temptabel.length);
                        this.series = [];
                        for (let i = 0; i < temptabel.length; i++) {
                            this.series.push({
                                name: temptabel[i].KodeKlasifikasiEvkin + ' (' + temptabel[i].KlasifikasiEvkin + ')',
                                data: [temptabel[i].JumlahP3A]
                            });
                        }

                        this.jumlahdata = Response.data.content.jumlahdata;
                        this.jumlahhalaman = Response.data.content.jumlahhalaman;
                        this.mulai = Response.data.content.mulai;
                        if (this.mulai == 0) {
                            this.halaman = 1;
                        }
                        else {
                            this.halaman = (this.mulai / 50) + 1;
                        }
                    }
                    else {

                        this.datamaster = [];
                        this.series = [];
                        this.pberlembaga = 0;
                        this.ptakberlembaga = 0;
                        this.TotalLaki2PengurusP3A = 0;
                        this.TotalPerempuanPengurusP3A = 0;
                        this.TotalJmlPengurusP3A = 0;
                        this.TotalJmlAnggotaP3A = 0;
                        this.TotalJmlTPM = 0;
                        this.TotalP3A = 0;
                        this.TotalP3Aevkin = 0;
                        this.klasifikasi = 0;
                        this.jumlahdata = 0;
                        this.jumlahhalaman = 0;
                        this.mulai = 0;
                        this.halaman = 1;
                    }
                    this.halamanloading = false;
                }
            ).catch(error => {
                this.datapesan = [];
                this.datamaster = [];
                this.halamanloading = false;
            });
        },
        mappingdata(kode) {
            var bytes = encodeURIComponent(CryptoJS.AES.encrypt(kode, 'oriproMop8LQ06S0rZXcJyEH3wk8jVrINbHwn7tBq2').toString());
            window.location.replace("/MappingP3A/" + bytes);
        },
        prosesdata(kode) {
            var bytes = encodeURIComponent(CryptoJS.AES.encrypt(kode, 'oriproMop8LQ06S0rZXcJyEH3wk8jVrINbHwn7tBq2').toString());
            return false;
        },
        hapus(kode) {
            swal.fire({
                title: "Konfirmasi",
                text: "Yakin Hendak Hapus Data?",
                icon: "warning",
                showCancelButton: true,
                cancelButtonText: "Batal",
                confirmButtonColor: "#2f416d",
                cancelButtonColor: "#ffaa05",
                confirmButtonText: "Hapus"
            }).then((result) => {
                if (result.isConfirmed) {
                    const data = { kode: kode, deletedby: JSON.parse(localStorage.getItem("usistem")) }
                    const mainAPI = axios.create({
                        baseURL: process.env.VUE_APP_URL_API,
                        headers: {
                            Authorization: "Bearer " + localStorage.getItem("tokensistem"),
                            "Content-Type": "application/x-www-form-urlencoded",
                        },
                    });
                    mainAPI.post("parastapainnovation_di-DeleteDI", data).then(
                        Response => {
                            if (Response.data.response == 'error') {
                                swal.fire('Peringatan', Response.data.message, 'error');
                            }
                            else {
                                swal.fire('Informasi', 'Data telah dihapus..', 'success').then(function () {
                                    window.location.replace("/DaerahIrigasi");
                                });
                            }
                        }
                    ).catch(
                        error => {
                            swal.fire('Peringatan', 'Terjadi Kesalahan Format Data', 'error');
                        }
                    )

                }
            });
            return false;
        },
    },
    mounted() {
        this.getOtentifikasi();
        this.ambildataReffPublik();
        this.ambildata();
    }
}
</script>
<style></style>