<template>
    <!-- Main Sidebar Container -->
    <aside class="main-sidebar elevation-4 sidebar-dark-marron">
        <a href="#" class="brand-link animation__shake wow fadeInDown"
            style="align-items: center;position: absolute;top: 25px;" data-widget="pushmenu" role="button">
            <center>
                <i class="fas fa-align-right text-white"></i>&nbsp;<img src="../../../assets/img/PortalLogo.png"
                    alt="Admin Logo" class="animation__shake wow fadeInDown" style="opacity: .8;height: 50px;">
            </center>
        </a>
        <!-- Sidebar -->
        <div class="sidebar wow fadeInLeft">
            <!-- Sidebar Menu -->
            <nav class="mt-2">
                <ul class="nav nav-pills nav-sidebar flex-column nav-flat" data-widget="treeview" role="menu"
                    data-accordion="false">
                    <li class="nav-item">&nbsp;</li>
                    <li class="nav-item">&nbsp;</li>
                    <li class="nav-item">
                        <router-link to="/Dashboard"
                            :class="[(route.name == 'Dashboard' ? 'nav-link active' : 'nav-link')]" id="Dashboard"><i
                                class="nav-icon fas fa-chart-line"></i>
                            <p>DASHBOARD</p>
                        </router-link>
                    </li>
                    <li class="nav-item">
                        <router-link to="/DaerahIrigasi"
                            :class="[((route.name == 'DaerahIrigasi' || route.name == 'ProsesDaerahIrigasi') ? 'nav-link active' : 'nav-link')]"
                            id="DaerahIrigasi"><i class="nav-icon fas fa-bezier-curve"></i>
                            <p>DATA DAERAH IRIGASI</p>
                        </router-link>
                    </li>
                    <li class="nav-item">
                        <router-link to="/IP3A"
                            :class="[((route.name == 'IP3A' || route.name == 'ProsesIP3A') ? 'nav-link active' : 'nav-link')]"
                            id="IP3A"><i class="nav-icon far fa-file-archive"></i>
                            <p>IP3A</p>
                        </router-link>
                    </li>
                    <li class="nav-item">
                        <router-link to="/GP3A"
                            :class="[((route.name == 'GP3A' || route.name == 'ProsesGP3A') ? 'nav-link active' : 'nav-link')]"
                            id="GP3A"><i class="nav-icon fab fa-flipboard"></i>
                            <p>GP3A</p>
                        </router-link>
                    </li>
                    <li class="nav-item">
                        <router-link to="/P3A"
                            :class="[((route.name == 'P3A' || route.name == 'ProsesP3A' || route.name == 'MappingP3A') ? 'nav-link active' : 'nav-link')]"
                            id="P3A"><i class="nav-icon fab fa-buromobelexperte"></i>
                            <p>P3A</p>
                        </router-link>
                    </li>
                    <li :class="[((route.name == 'ImportExcel' || route.name == 'ProsesImportExcel') ? 'nav-item menu-open' : 'nav-item')]">
                        <a href="#" :class="[((route.name == 'ImportExcel' || route.name == 'ProsesImportExcel') ? 'nav-link active' : 'nav-link')]">
                            <i class="nav-icon far fa-file-excel"></i>
                            <p>
                                IMPORT DATA EXCEL
                                <i class="right fas fa-angle-left"></i>
                            </p>
                        </a>
                        <ul class="nav nav-treeview">
                            <li class="nav-item">
                                <router-link to="/ImportExcel"
                                    :class="[((route.name == 'ImportExcel' || route.name == 'ProsesImportExcel') ? 'nav-link active' : 'nav-link')]"
                                    id="ImportExcel"><i class="far fa-circle nav-icon"></i>
                                    <p>P3A</p>
                                </router-link>
                            </li>
                            <li class="nav-item">
                                <a href="#" class="nav-link">
                                    <i class="far fa-circle nav-icon"></i>
                                    <p>TPM</p>
                                </a>
                            </li>
                            <li class="nav-item">
                                <a href="#" class="nav-link">
                                    <i class="far fa-circle nav-icon"></i>
                                    <p>UPI</p>
                                </a>
                            </li>
                            <li class="nav-item">
                                <a href="#" class="nav-link">
                                    <i class="far fa-circle nav-icon"></i>
                                    <p>PTGA</p>
                                </a>
                            </li>
                        </ul>
                    </li>
                    <li class="nav-item">
                        <router-link to="/Slider"
                            :class="[((route.name == 'Slider' || route.name == 'ProsesSlider') ? 'nav-link active' : 'nav-link')]"
                            id="Slider"><i class="nav-icon fas fa-photo-video"></i>
                            <p>SLIDER GAMBAR</p>
                        </router-link>
                    </li>
                    <li class="nav-item">
                        <router-link to="/InfoBeranda"
                            :class="[((route.name == 'InfoBeranda' || route.name == 'ProsesInfoBeranda') ? 'nav-link active' : 'nav-link')]"
                            id="InfoBeranda"><i class="nav-icon fas fa-box"></i>
                            <p>INFO BERANDA</p>
                        </router-link>
                    </li>
                    <li class="nav-item">
                        <router-link to="/Regulasi"
                            :class="[((route.name == 'Regulasi' || route.name == 'ProsesRegulasi') ? 'nav-link active' : 'nav-link')]"
                            id="Regulasi"><i class="nav-icon far fa-file-pdf"></i>
                            <p>REGULASI</p>
                        </router-link>
                    </li>
                    <li class="nav-item">
                        <router-link to="/APIPeta"
                            :class="[((route.name == 'APIPeta' || route.name == 'ProsesAPIPeta') ? 'nav-link active' : 'nav-link')]"
                            id="APIPeta"><i class="nav-icon fas fa-compress-alt"></i>
                            <p>API PETA</p>
                        </router-link>
                    </li>
                    <li class="nav-item">
                        <router-link to="/KategoriData"
                            :class="[((route.name == 'KategoriData' || route.name == 'ProsesKategoriData') ? 'nav-link active' : 'nav-link')]"
                            id="KategoriData"><i class="nav-icon fas fa-boxes"></i>
                            <p>KATEGORI DATA PETA</p>
                        </router-link>
                    </li>
                    <li class="nav-item">
                        <router-link to="/LayerPeta"
                            :class="[((route.name == 'LayerPeta' || route.name == 'ProsesLayerPeta') ? 'nav-link active' : 'nav-link')]"
                            id="LayerPeta"><i class="nav-icon fas fa-layer-group"></i>
                            <p>LAYER PETA</p>
                        </router-link>
                    </li>
                    <li class="nav-item">
                        <a href="#" class="nav-link" @click="keluar()">
                            <i class="nav-icon fas fa-sign-out-alt"></i>
                            <p>KELUAR / LOGOUT</p>
                        </a>
                    </li>
                    <li>&nbsp;</li>
                </ul>
            </nav>
            <!-- /.sidebar-menu -->
        </div>
        <!-- /.sidebar -->
    </aside>
</template>

<script>
import { useRoute } from 'vue-router';
import { watch } from 'vue';
import swal from 'sweetalert2';
export default {
    components: {
        swal
    },
    setup() {
        const route = useRoute()
        watch(() => route.name, () => {
        });

        return { route };
    },
    data() {
        return {
            tahun: new Date().getFullYear(),
            namauser: JSON.parse(localStorage.getItem("nmusistem")),
            leveluser: JSON.parse(localStorage.getItem("lsistem")),
        };
    },
    methods: {
        getOtentifikasi() {
            var time = Date.parse(localStorage.getItem("tsistem"));
            var timeakhir = time + (3 * 60 * 60 * 1000);
            var timesekarang = Date.parse(new Date());
            if (timesekarang > timeakhir) {
                localStorage.removeItem('tokensistem');
                localStorage.removeItem('usistem');
                localStorage.removeItem('lsistem');
                localStorage.removeItem('nmlsistem');
                localStorage.removeItem('emlsistem');
                localStorage.removeItem('nmusistem');
                localStorage.removeItem('tsistem');
                localStorage.removeItem('orsistem');
                localStorage.removeItem('bdlsistem');
                localStorage.removeItem('sbdsistem');
                localStorage.removeItem('jbtsistem');
            }
        },
        keluar() {
            swal.fire({
                title: "Konfirmasi",
                text: "Yakin Hendak Keluar Aplikasi?",
                icon: "warning",
                showCancelButton: true,
                cancelButtonText: "Batal",
                confirmButtonColor: "#2f416d",
                cancelButtonColor: "#ffaa05",
                confirmButtonText: "Keluar"
            }).then((result) => {
                if (result.isConfirmed) {
                    this.ClearlocalStorage();
                }
            });

            return false;
        },
    },
    mounted() {
        this.getOtentifikasi();
        if ((localStorage.getItem("tokensistem") == null)) {
            window.location.replace("/Login");
        }
    }
}
</script>

<style></style>