<template>
    <Top />
    <!-- Spinner Start -->
    <div id="spinner" v-if="halamanloading"
        class="show bg-white position-fixed translate-middle w-100 vh-100 top-50 start-50 d-flex align-items-center justify-content-center">
        <div class="spinner-border text-danger" style="width: 3rem; height: 3rem;" role="status">
            <span class="sr-only">Loading...</span>
        </div>
        &nbsp;<img src="../../../../src/assets/img/disdag.png" height="40">
        &nbsp;<span class="text-danger">..Loading...</span>
    </div>
    <!-- Spinner End -->
    <!-- Header Start -->
    <div class="container-fluid bg-breadcrumb">
        <div class="container text-center py-5" style="max-width: 900px;">
            <h3 class="text-white display-3 mb-4 wow fadeInDown pmisputih" data-wow-delay="0.1s">
                <span v-if="(bahasa === null) || (bahasa == 'ina')">KONTAK KAMI</span>
                <span v-else>CONTACT US</span>
            </h3>
            <ol class="breadcrumb justify-content-center mb-0 wow fadeInDown" data-wow-delay="0.3s">
                <li class="breadcrumb-item">
                    <a href="/">
                        <span v-if="(bahasa === null) || (bahasa == 'ina')">Beranda</span>
                        <span v-else>Home</span>
                    </a>
                </li>
                <li class="breadcrumb-item active text-warning">
                    <span v-if="(bahasa === null) || (bahasa == 'ina')">Kontak Kami</span>
                    <span v-else>Contact Us</span>
                </li>
            </ol>
        </div>
    </div>
    <!-- Header End -->
    <!-- Contact Start -->
    <div class="container-fluid blog py-2">&nbsp;</div>
    <div class="container-fluid bg-breadcrumbberanda wow fadeInDown">
        <div class="section-title mb-2 wow fadeInDown" data-wow-delay="0.1s">
            <div class="col-lg-12 col-xl-12 wow fadeInRight" data-wow-delay="0.3s">
                <div class="col-lg-12 col-xl-12 wow fadeInRight" data-wow-delay="0.3s">
                    <span v-if="(bahasa === null) || (bahasa == 'ina')"> Jl. Pattimura No. 20 Kebayoran Baru Jakarta Selatan 12110
                    </span>
                </div>
                <div class="col-lg-12 col-xl-12 wow fadeInRight" data-wow-delay="0.3s">&nbsp;</div>
                <div class="col-lg-12 col-xl-12 wow fadeInRight" data-wow-delay="0.3s">
                    <div class="d-flex justify-content-center mb-4">
                        <a class="btn btn-lg-square btn-light rounded-circle mx-2"
                            href="https://www.youtube.com/@pupr_sda9754" target="_blank"><i
                                class="fab fa-youtube"></i></a>
                        <a class="btn btn-lg-square btn-light rounded-circle mx-2"
                            href="https://www.instagram.com/pupr_sda/" target="_blank"><i
                                class="fab fa-instagram"></i></a>
                        <a class="btn btn-lg-square btn-light rounded-circle mx-2"
                            href="https://x.com/pupr_sda" target="_blank"><i
                                class="fab fa-twitter"></i></a>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="container-fluid blog py-2">
        <div class="container py-2">
            <div class="section-title mb-2 wow fadeInDown" data-wow-delay="0.1s">
                <div class="col-lg-12 col-xl-12 wow fadeInRight" data-wow-delay="0.3s">
                    <div class="rounded h-100">
                        <iframe class="rounded w-100" style="height: 500px;" :src="sourcenya" loading="lazy"
                            referrerpolicy="no-referrer-when-downgrade" @load="loadkontak()"></iframe>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- Contact End -->
    <a href="#" class="btn btn-dangerbulet btn-lg-square back-to-top text-white"><i class="fa fa-arrow-up"></i></a>
    <Footer />
</template>

<script>
import axios from 'axios';
import $ from 'jquery';
import swal from 'sweetalert2';

import Top from "./Template/Top.vue";
import Footer from "./Template/Footer.vue";

export default {
    components: {
        Top, Footer, swal
    },
    data() {
        return {
            halamanloading: true,
            tahun: new Date().getFullYear(),
            bahasa: localStorage.getItem("bahasasistem"),
            sourcenya: '',
        };
    },
    methods: {
        loadkontak() {
            this.halamanloading = true;
            const message = this.linknya;
            const iframe = document.querySelector("iframe");
            this.sourcenya = '';
            this.sourcenya = 'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3966.1994263712386!2d106.79862667499044!3d-6.237424843750817!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2e69f14420189c2d%3A0xe94f50f053b914ea!2sDirektorat%20Jenderal%20Sumber%20Daya%20Air!5e0!3m2!1sid!2sid!4v1719687444632!5m2!1sid!2sid';
            iframe.contentWindow.postMessage(message, "*");
            this.halamanloading = false;
        },
    },
    mounted() {
        this.halamanloading = true;
    },
}
</script>

<style></style>