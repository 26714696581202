<template>
    <Top />
    <!-- Spinner Start -->
    <div id="spinner" v-if="halamanloading"
        class="show bg-white position-fixed translate-middle w-100 vh-100 top-50 start-50 d-flex align-items-center justify-content-center">
        <div class="spinner-border text-danger" style="width: 3rem; height: 3rem;" role="status">
            <span class="sr-only">Loading...</span>
        </div>
        &nbsp;<img src="../../../../src/assets/img/disdag.png" height="40">
        &nbsp;<span class="text-danger">..Loading...</span>
    </div>
    <!-- Spinner End -->
    <!-- Header Start -->
    <div class="container-fluid bg-breadcrumb">
        <div class="container text-center py-5" style="max-width: 900px;">
            <h3 class="text-white display-3 mb-4 wow fadeInDown pmisputih" data-wow-delay="0.1s">
                Unit Pengelola Irigasi (UPI)
            </h3>
            <ol class="breadcrumb justify-content-center mb-0 wow fadeInDown" data-wow-delay="0.3s">
                <li class="breadcrumb-item">
                    <a href="/">
                        Beranda
                    </a>
                </li>
                <li class="breadcrumb-item active text-warning">
                    UPI
                </li>
            </ol>
        </div>
    </div>
    <!-- Header End -->
    <!-- Contact Start -->
    <div class="container-fluid blog py-2">
        &nbsp;
    </div>
    <div class="container-fluid bg-breadcrumbberanda px-5 wow fadeInDown">
        <div class="card collapsed-card">
            <div class="card-header" data-card-widget="collapse" style="cursor: pointer;">
                <h5 class="card-title">Filter Data</h5>

                <div class="card-tools">
                    <button type="button" class="btn btn-tool" data-card-widget="collapse">
                        <i class="fas fa-plus"></i>
                    </button>
                </div>
            </div>
            <!-- /.card-header -->
            <div class="card-body">
                <div class="row">
                    <div class="col-sm-4">
                        <label>Pilihan Kewenangan </label>
                        <v-select :options="kewenangan" :reduce="(label) => label.code" label="label"
                            v-model="defaultSelectedkewenangan" @update:modelValue="onkewenanganChange"
                            style="background-color: #ffffff;"></v-select>
                    </div>
                    <div class="col-sm-4">
                        <label>Pilihan Daerah Irigasi </label>
                        <v-select :options="kodeDI" :reduce="(label) => label.code" label="label"
                            v-model="defaultSelectedkodeDI" @update:modelValue="onkodeDIChange"
                            style="background-color: #ffffff;"></v-select>
                    </div>
                    <div class="col-sm-4">
                        <label>Pilihan Balai </label>
                        <v-select :options="balai" :reduce="(label) => label.code" label="label"
                            v-model="defaultSelectedbalai" @update:modelValue="onbalaiChange"
                            style="background-color: #ffffff;"></v-select>
                    </div>
                </div>
                <!-- /.row -->
            </div>
            <!-- ./card-body -->
            <div class="card-footer">

            </div>
            <!-- /.card-footer -->
        </div>
    </div>
    <div class="container-fluid bg-breadcrumbberanda px-5">
        <div class="card">
            <div class="card-header" data-card-widget="collapse" style="cursor: pointer;">
                <h5 class="card-title">Daftar TPM</h5>

                <div class="card-tools">
                    <button type="button" class="btn btn-tool" data-card-widget="collapse">
                        <i class="fas fa-minus"></i>
                    </button>
                </div>
            </div>
            <!-- /.card-header -->
            <div class="card-body">
                <div class="row">
                    <div class="col-sm-12 float-left pb-2 px-3">
                        <div class="card card-warning collapsed-card">
                            <div class="card-header" data-card-widget="collapse" style="cursor: pointer;">
                                <h3 class="card-title">Show / Hide Kolom</h3>
                                <div class="card-tools">
                                    <button type="button" class="btn btn-tool" data-card-widget="collapse"><i
                                            class="fas fa-plus" style="color: #ffffff;"></i>
                                    </button>
                                </div>
                            </div>
                            <div class="card-body">
                                <div class="card">
                                    <div class="card-body">
                                        <div class="row">
                                            <div class="col-lg-2 col-12">
                                                <div class="custom-control custom-checkbox">
                                                    <input class="custom-control-input custom-control-input-warning"
                                                        type="checkbox" id="customCheckboxKode" value="optionKode"
                                                        @click="showColumnKode = !showColumnKode">
                                                    <label for="customCheckboxKode" class="custom-control-label">
                                                        Kode D.I.</label>
                                                </div>
                                            </div>
                                            <div class="col-lg-2 col-12">
                                                <div class="custom-control custom-checkbox">
                                                    <input class="custom-control-input custom-control-input-warning"
                                                        type="checkbox" id="customCheckboxNamaDI" value="optionNamaDI"
                                                        @click="showColumnNamaDI = !showColumnNamaDI">
                                                    <label for="customCheckboxNamaDI" class="custom-control-label">
                                                        Nama D.I.</label>
                                                </div>
                                            </div>
                                            <div class="col-lg-2 col-12">
                                                <div class="custom-control custom-checkbox">
                                                    <input class="custom-control-input custom-control-input-warning"
                                                        type="checkbox" id="customCheckboxBalai" value="optionBalai"
                                                        @click="showColumnBalai = !showColumnBalai">
                                                    <label for="customCheckboxBalai" class="custom-control-label">
                                                        Balai</label>
                                                </div>
                                            </div>
                                            <div class="col-lg-2 col-12">
                                                <div class="custom-control custom-checkbox">
                                                    <input class="custom-control-input custom-control-input-warning"
                                                        type="checkbox" id="customCheckboxKewenangan"
                                                        value="optionKewenangan" @click="showColumnKewenangan = !showColumnKewenangan">
                                                    <label for="customCheckboxKewenangan" class="custom-control-label">
                                                        Kewenangan</label>
                                                </div>
                                            </div>
                                            <div class="col-lg-2 col-12">
                                                <div class="custom-control custom-checkbox">
                                                    <input class="custom-control-input custom-control-input-warning"
                                                        type="checkbox" id="customCheckboxUPI" value="optionUPI"
                                                        @click="showColumnUPI = !showColumnUPI" checked>
                                                    <label for="customCheckboxUPI" class="custom-control-label">
                                                        UPI</label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-12 float-left pb-2 px-3 wow fadeInDown">
                        Jumlah Data: {{ G_numFormat(jumlahdata) }}
                        <span style="float: right;">
                            Halaman ke: <select v-model="halaman" @change="onChangeHalaman($event)">
                                <option v-for="index in jumlahhalaman" :key="index">
                                    {{ index }}</option>
                            </select> dari {{ jumlahhalaman }}
                        </span>
                    </div>
                    <div class="col-sm-12 float-left pb-2 wow fadeInDown">
                        <input type="text" v-model="carikata" class="form-control form-control-sm"
                            placeholder="Masukkan Kata Kunci Pencarian Halaman Terpilih">
                    </div>
                    <div class="table-responsive table-wrapper">
                        <table class="table m-0" id="tabeldata">
                            <thead>
                                <tr>
                                    <th style="width: 50px;">No</th>
                                    <th style="width: 100px;" v-if="showColumnKode">Kode D.I</th> 
                                    <th style="width: 100px;" v-if="showColumnNamaDI">Daerah Irigasi</th> 
                                    <th style="width: 100px;" v-if="showColumnBalai">Balai</th> 
                                    <th style="width: 100px;" v-if="showColumnKewenangan">Kewenangan</th> 
                                    <th style="width: 100px;" v-if="showColumnUPI">UPI</th>  
                                    <th style="width: 100px;">Nama</th> 
                                    <th style="width: 100px;">NIK</th>
                                    <th style="width: 50px;">Jabatan</th>
                                    <th style="width: 50px;">Jenis Kelamin</th>
                                    <th style="width: 50px;">No. Telp</th>
                                </tr>
                            </thead>
                            <template v-for="(datalist, urutlist) in filteredKataData" :key="urutlist">
                                <tbody>
                                    <tr>
                                        <td class="text-center">{{ urutlist + 1 }}</td>
                                        <td class="text-justify" v-if="showColumnKode">{{ datalist.kodedi }}</td>
                                        <td class="text-justify" v-if="showColumnNamaDI">{{ datalist.daerahirigasi }}</td>
                                        <td class="text-justify" v-if="showColumnBalai">{{ datalist.namabalai }}</td>
                                        <td class="text-justify" v-if="showColumnKewenangan">{{ datalist.kewenangan }}</td>
                                        <td class="text-justify" v-if="showColumnUPI">{{ datalist.namaupi }}</td>
                                        <td class="text-justify">{{ datalist.nama }}</td>
                                        <td class="text-justify">{{ datalist.nik }}</td>
                                        <td class="text-justify">{{ datalist.jabatan }}</td>
                                        <td class="text-justify">{{ datalist.jeniskelamin }}</td>
                                        <td class="text-justify">{{ datalist.notelp }}</td>
                                    </tr>
                                </tbody>
                            </template>
                        </table>
                    </div>
                </div>
                <!-- /.row -->
            </div>
            <!-- ./card-body -->
            <div class="card-footer">

            </div>
            <!-- /.card-footer -->
        </div>
    </div>
    <div class="col-sm-12">&nbsp;</div>
    <!-- Contact End -->
    <a href="#" class="btn btn-dangerbulet btn-lg-square back-to-top text-white"><i class="fa fa-arrow-up"></i></a>
    <Footer />
</template>

<script>
import axios from 'axios';
import $ from 'jquery';
import CryptoJS from 'crypto-js';
import { useRoute } from 'vue-router';
import { watch } from 'vue';
import swal from 'sweetalert2';
import vSelect from 'vue-select';
import VueApexCharts from "vue3-apexcharts";
window.Apex.chart = { fontFamily: "Poppins-SemiBold, Arial, sans-serif" };
import Top from "./Template/Top.vue";
import Footer from "./Template/Footer.vue";

export default {
    setup() {
        const route = useRoute()
        watch(() => route.name, () => {
        });

        return { route };
    },
    components: {
        Top, Footer, swal, vSelect, apexchart: VueApexCharts,
    },
    data() {
        return {
            halamanloading: true,
            secretencData: decodeURIComponent(CryptoJS.AES.decrypt(this.route.params.id, 'oriproMop8LQ06S0rZXcJyEH3wk8jVrINbHwn7tBq2').toString(CryptoJS.enc.Utf8)),
            tahun: new Date().getFullYear(),
            bahasa: localStorage.getItem("bahasasistem"),
            datapesan: '',
            datamaster: [],
            klasifikasi: [],
            carikata: '',
            showColumnKode: false,
            showColumnBalai: false,
            showColumnKewenangan: false,
            showColumnNamaDI: false,
            showColumnIP3A: false,
            showColumnGP3A: false,
            showColumnUPI: true,
            jumlahdata: 0,
            jumlahhalaman: 0,
            mulai: 0,
            halaman: 1,
            kewenangan: [],
            datakewenangan: [],
            defaultSelectedkewenangan: {
                code: 0,
                label: 'Semua Data',
                tag: '',
            },
            selectedkewenangan: '',
            kodeDI: [],
            datakodeDI: [],
            defaultSelectedkodeDI: {
                code: 0,
                label: 'Semua Data',
                tag: '',
            },
            selectedkodeDI: '',
            balai: [],
            databalai: [],
            defaultSelectedbalai: {
                code: 0,
                label: 'Semua Data',
                tag: '',
            },
            selectedbalai: '',
            totalsaluran: 0,
            totalbangunan: 0,
            totalpetak: 0,
        };
    },
    computed: {
        filteredKataData() {
            const filteredWorkers = this.carikata === ""
                ? this.datamaster
                : this.datamaster.filter(wo => Object.values(wo).join("").toLowerCase().indexOf(this.carikata.toLowerCase()) !== -1);
            return filteredWorkers;
        },
        JumlahfilteredKataData() {
            var jumlah = 0;
            try {
                if (this.filteredKataData === undefined) {
                    jumlah = 0;
                } else {
                    jumlah = this.filteredKataData.length;
                }
            }
            catch {
                jumlah = 0;
            }
            return jumlah;
        },
    },
    methods: {
        async ambildataReffPublik() {
            this.halamanloading = true;
            const mainAPIReFF = axios.create({
                baseURL: process.env.VUE_APP_URL_API,
                headers: {
                    Authorization: "Bearer GPMop8LQ06S0rZXcJyEH3wk8jVrINbHwn7tBq2",
                },
            });
            var random = Math.random();
            await mainAPIReFF.get("parastapainnovation_p-GetKewenangan?random=" + random).then(
                Response => {
                    this.datapesan = Response.data.message;
                    if (this.datapesan == 'data diketemukan') {
                        this.datakewenangan = [];
                        this.kewenangan = [];
                        this.datakewenangan = Response.data.content.data;
                        this.datakewenangan.forEach((item) => {
                            this.kewenangan.push({
                                label: item.nama,
                                code: this.datakewenangan.indexOf(item) + 1,
                                tag: item.id,
                            });
                        });
                        this.kewenangan.unshift({
                            label: 'Semua Data',
                            code: 0,
                            tag: '',
                        });
                    }
                    else {
                        this.datakewenangan = [];
                        this.kewenangan = [];
                        this.kewenangan.unshift({
                            label: 'Semua Data',
                            code: 0,
                            tag: '',
                        });
                    }
                    this.halamanloading = false;
                }
            ).catch(error => {
                swal.fire('Peringatan', 'Kesalahan fecthing data', 'error');
                this.halamanloading = false;
                return false;
            })
            await mainAPIReFF.get("parastapainnovation_p-GetDI?random=" + random + "&kewenangan=" + this.selectedkewenangan).then(
                Response => {
                    this.datapesan = Response.data.message;
                    if (this.datapesan == 'data diketemukan') {
                        this.datakodeDI = [];
                        this.kodeDI = [];
                        this.datakodeDI = Response.data.content.data;
                        this.datakodeDI.forEach((item) => {
                            this.kodeDI.push({
                                label: '[' + item.kode + '] ' + item.nama,
                                code: this.datakodeDI.indexOf(item) + 1,
                                tag: item.kode,
                            });
                        });
                        this.kodeDI.unshift({
                            label: 'Semua Data',
                            code: 0,
                            tag: '',
                        });
                    }
                    else {
                        this.datakodeDI = [];
                        this.kodeDI = [];
                        this.kodeDI.unshift({
                            label: 'Semua Data',
                            code: 0,
                            tag: '',
                        });
                    }
                    this.halamanloading = false;
                }
            ).catch(error => {
                swal.fire('Peringatan', 'Kesalahan fecthing data', 'error');
                this.halamanloading = false;
                return false;
            })
            await mainAPIReFF.get("parastapainnovation_p-GetBalai?random=" + random + "&kewenangan=" + this.selectedkewenangan).then(
                Response => {
                    this.datapesan = Response.data.message;
                    if (this.datapesan == 'data diketemukan') {
                        this.databalai = [];
                        this.balai = [];
                        this.databalai = Response.data.content.data;
                        this.databalai.forEach((item) => {
                            this.balai.push({
                                label: item.nama,
                                code: this.databalai.indexOf(item) + 1,
                                tag: item.id,
                            });
                        });
                        this.balai.unshift({
                            label: 'Semua Data',
                            code: 0,
                            tag: '',
                        });
                    }
                    else {
                        this.databalai = [];
                        this.balai = [];
                        this.balai.unshift({
                            label: 'Semua Data',
                            code: 0,
                            tag: '',
                        });
                    }
                    this.halamanloading = false;
                }
            ).catch(error => {
                swal.fire('Peringatan', 'Kesalahan fecthing data', 'error');
                this.halamanloading = false;
                return false;
            })
        },
        async filterdataReffPublik() {
            this.halamanloading = true;
            const mainAPIReFF = axios.create({
                baseURL: process.env.VUE_APP_URL_API,
                headers: {
                    Authorization: "Bearer GPMop8LQ06S0rZXcJyEH3wk8jVrINbHwn7tBq2",
                },
            });
            var random = Math.random();
            //alert(this.selectedkewenangan);
            await mainAPIReFF.get("parastapainnovation_p-GetDI?random=" + random + "&kewenangan=" + this.selectedkewenangan).then(
                Response => {
                    this.datapesan = Response.data.message;
                    if (this.datapesan == 'data diketemukan') {
                        this.datakodeDI = [];
                        this.kodeDI = [];
                        this.datakodeDI = Response.data.content.data;
                        this.datakodeDI.forEach((item) => {
                            this.kodeDI.push({
                                label: '[' + item.kode + '] ' + item.nama,
                                code: this.datakodeDI.indexOf(item) + 1,
                                tag: item.kode,
                            });
                        });
                        this.kodeDI.unshift({
                            label: 'Semua Data',
                            code: 0,
                            tag: '',
                        });
                    }
                    else {
                        this.datakodeDI = [];
                        this.kodeDI = [];
                        this.kodeDI.unshift({
                            label: 'Semua Data',
                            code: 0,
                            tag: '',
                        });
                    }
                    this.halamanloading = false;

                }
            ).catch(error => {
                swal.fire('Peringatan', 'Kesalahan fecthing data', 'error');
                this.halamanloading = false;
                return false;
            })
            await mainAPIReFF.get("parastapainnovation_p-GetBalai?random=" + random + "&kewenangan=" + this.selectedkewenangan).then(
                Response => {
                    this.datapesan = Response.data.message;
                    if (this.datapesan == 'data diketemukan') {
                        this.databalai = [];
                        this.balai = [];
                        this.databalai = Response.data.content.data;
                        this.databalai.forEach((item) => {
                            this.balai.push({
                                label: item.nama,
                                code: this.databalai.indexOf(item) + 1,
                                tag: item.id,
                            });
                        });
                        this.balai.unshift({
                            label: 'Semua Data',
                            code: 0,
                            tag: '',
                        });
                    }
                    else {
                        this.databalai = [];
                        this.balai = [];
                        this.balai.unshift({
                            label: 'Semua Data',
                            code: 0,
                            tag: '',
                        });
                    }
                    this.halamanloading = false;
                }
            ).catch(error => {
                swal.fire('Peringatan', 'Kesalahan fecthing data', 'error');
                this.halamanloading = false;
                return false;
            })

            this.pindahhalaman(1);
        },
        onkewenanganChange(a) {
            try {
                this.selectedkewenangan = this.kewenangan[a].tag;
            }
            catch (err) {
                return;
            }
            this.defaultSelectedkodeDI = {
                code: 0,
                label: 'Semua Data',
                tag: '',
            };
            this.selectedkodeDI = '';
            this.defaultSelectedbalai = {
                code: 0,
                label: 'Semua Data',
                tag: '',
            };
            this.selectedbalai = '';
            this.filterdataReffPublik();
        },
        onbalaiChange(a) {
            try {
                this.selectedbalai = this.balai[a].tag;
            }
            catch (err) {
                return;
            }
            this.filterdataReffPublik();
        },
        onkodeDIChange(a) {
            try {
                this.selectedkodeDI = this.kodeDI[a].tag;
            }
            catch (err) {
                return;
            }
            this.filterdataReffPublik();
        },
        onChangeHalaman(event) {
            this.pindahhalaman(event.target.value);
        },
        async pindahhalaman(halamannya) {
            this.halamanloading = true;
            const mainAPI = axios.create({
                baseURL: process.env.VUE_APP_URL_API,
                headers: {
                    Authorization: "Bearer GPMop8LQ06S0rZXcJyEH3wk8jVrINbHwn7tBq2",
                },
            });
            //alert(this.selectedkewenangan);
            var random = Math.random();
            await mainAPI.get("parastapainnovation_upi-GetUPI?random=" + random + "&halaman=" + halamannya + "&kodeDI=" + this.selectedkodeDI + "&balai=" + this.selectedbalai + "&kewenangan=" + this.selectedkewenangan).then(
                Response => {

                    this.datapesan = Response.data.message;
                    if (this.datapesan == 'data diketemukan') {
                        this.datamaster = [];
                        this.datamaster = Response.data.content.data;
                        
                        this.jumlahdata = Response.data.content.jumlahdata;
                        this.jumlahhalaman = Response.data.content.jumlahhalaman;
                        this.mulai = Response.data.content.mulai;
                        if (this.mulai == 0) {
                            this.halaman = 1;
                        }
                        else {
                            this.halaman = (this.mulai / 50) + 1;
                        }
                    }
                    else {

                        this.datamaster = [];
                        this.jumlahdata = 0;
                        this.jumlahhalaman = 0;
                        this.mulai = 0;
                        this.halaman = 1;
                    }
                    this.halamanloading = false;
                }
            ).catch(error => {
                this.datapesan = [];
                this.datamaster = [];
                this.halamanloading = false;
            });
        },
        async ambildata() {
            this.halamanloading = true;
            const mainAPI = axios.create({
                baseURL: process.env.VUE_APP_URL_API,
                headers: {
                    Authorization: "Bearer GPMop8LQ06S0rZXcJyEH3wk8jVrINbHwn7tBq2",
                },
            });
            var random = Math.random();
            await mainAPI.get("parastapainnovation_upi-GetUPI?random=" + random + "&kodeDI=" + this.selectedkodeDI + "&balai=" + this.selectedbalai + "&kewenangan=" + this.selectedkewenangan).then(
                Response => {

                    this.datapesan = Response.data.message;
                    if (this.datapesan == 'data diketemukan') {
                        this.datamaster = [];
                        this.datamaster = Response.data.content.data;
                        this.jumlahdata = Response.data.content.jumlahdata;
                        this.jumlahhalaman = Response.data.content.jumlahhalaman;
                        this.mulai = Response.data.content.mulai;
                        if (this.mulai == 0) {
                            this.halaman = 1;
                        }
                        else {
                            this.halaman = (this.mulai / 50) + 1;
                        }
                    }
                    else {

                        this.datamaster = [];
                        this.jumlahdata = 0;
                        this.jumlahhalaman = 0;
                        this.mulai = 0;
                        this.halaman = 1;
                    }
                    this.halamanloading = false;
                }
            ).catch(error => {
                this.datapesan = [];
                this.datamaster = [];
                this.halamanloading = false;
            });
        }
    },
    mounted() {
        this.ambildataReffPublik();
        this.ambildata();
    },
}
</script>

<style></style>