import { createRouter, createWebHistory } from 'vue-router'

const routes = [
  {
    path: '/',
    name: 'Beranda',
    component: () => import('../../components/views/Publik/Beranda.vue'),
    meta: {
      transition: "zoom",
    },
  },
  {
    path: '/Login',
    name: 'Login',
    component: () => import('../../components/views/Publik/Login.vue'),
    meta: {
      transition: "zoom",
    },
  },
  {
    path: '/KontakKami',
    name: 'KontakKami',
    component: () => import('../../components/views/Publik/KontakKami.vue'),
    meta: {
      transition: "zoom",
    },
  },
  {
    path: '/DaftarRegulasi',
    name: 'DaftarRegulasi',
    component: () => import('../../components/views/Publik/DaftarRegulasi.vue'),
    meta: {
      transition: "zoom",
    },
  },
  {
    path: '/DaftarDaerahIrigasi',
    name: 'DaftarDaerahIrigasi',
    component: () => import('../../components/views/Publik/DaftarDaerahIrigasi.vue'),
    meta: {
      transition: "zoom",
    },
  },
  {
    path: '/DaftarIP3A/:id',
    name: 'DaftarIP3A',
    component: () => import('../../components/views/Publik/DaftarIP3A.vue'),
    meta: {
      transition: "zoom",
    },
  },
  {
    path: '/DaftarGP3A/:id',
    name: 'DaftarGP3A',
    component: () => import('../../components/views/Publik/DaftarGP3A.vue'),
    meta: {
      transition: "zoom",
    },
  },
  {
    path: '/DaftarP3A/:id',
    name: 'DaftarP3A',
    component: () => import('../../components/views/Publik/DaftarP3A.vue'),
    meta: {
      transition: "zoom",
    },
  },
  {
    path: '/DaftarTPM/:id',
    name: 'DaftarTPM',
    component: () => import('../../components/views/Publik/DaftarTPM.vue'),
    meta: {
      transition: "zoom",
    },
  },
  {
    path: '/DaftarUPI/:id',
    name: 'DaftarUPI',
    component: () => import('../../components/views/Publik/DaftarUPI.vue'),
    meta: {
      transition: "zoom",
    },
  },
  {
    path: '/DaftarPTGA/:id',
    name: 'DaftarPTGA',
    component: () => import('../../components/views/Publik/DaftarPTGA.vue'),
    meta: {
      transition: "zoom",
    },
  },
  {
    path: '/DaftarOP/:id',
    name: 'DaftarOP',
    component: () => import('../../components/views/Publik/DaftarOP.vue'),
    meta: {
      transition: "zoom",
    },
  },
  {
    path: '/DaftarKOMIR/:id',
    name: 'DaftarKOMIR',
    component: () => import('../../components/views/Publik/DaftarKOMIR.vue'),
    meta: {
      transition: "zoom",
    },
  },
  {
    path: '/Ringkasan/:id',
    name: 'Ringkasan',
    component: () => import('../../components/views/Publik/Ringkasan.vue'),
    meta: {
      transition: "zoom",
    },
  },
  {
    path: '/GIS/:id',
    name: 'GIS',
    component: () => import('../../components/views/Publik/GIS.vue'),
    meta: {
      transition: "zoom",
    },
  },
  {
    path: '/Dashboard',
    name: 'Dashboard',
    component: () => import('../../components/views/Dashboard/Dashboard.vue'),
    meta: {
      transition: "zoom",
    },
  },
  {
    path: '/ImportExcel',
    name: 'ImportExcel',
    component: () => import('../../components/views/ImportExcel/ImportExcel.vue'),
    meta: {
      transition: "zoom",
    },
  },
  {
    path: '/Slider',
    name: 'Slider',
    component: () => import('../../components/views/Backoffice/Slider/Slider.vue'),
    meta: {
      transition: "zoom",
    },
  },
  {
    path: '/ProsesSlider/:id',
    name: 'ProsesSlider',
    component: () => import('../../components/views/Backoffice/Slider/ProsesSlider.vue'),
    meta: {
      transition: "zoom",
    },
  },
  {
    path: '/P3A',
    name: 'P3A',
    component: () => import('../../components/views/Backoffice/P3A/P3A.vue'),
    meta: {
      transition: "zoom",
    },
  },
  {
    path: '/ProsesP3A/:id',
    name: 'ProsesP3A',
    component: () => import('../../components/views/Backoffice/P3A/ProsesP3A.vue'),
    meta: {
      transition: "zoom",
    },
  },
  {
    path: '/MappingP3A/:id',
    name: 'MappingP3A',
    component: () => import('../../components/views/Backoffice/P3A/MappingP3A.vue'),
    meta: {
      transition: "zoom",
    },
  },
  {
    path: '/Regulasi',
    name: 'Regulasi',
    component: () => import('../../components/views/Backoffice/Regulasi/Regulasi.vue'),
    meta: {
      transition: "zoom",
    },
  },
  {
    path: '/ProsesRegulasi/:id',
    name: 'ProsesRegulasi',
    component: () => import('../../components/views/Backoffice/Regulasi/ProsesRegulasi.vue'),
    meta: {
      transition: "zoom",
    },
  },
  {
    path: '/InfoBeranda',
    name: 'InfoBeranda',
    component: () => import('../../components/views/Backoffice/InfoBeranda/InfoBeranda.vue'),
    meta: {
      transition: "zoom",
    },
  },
  {
    path: '/ProsesInfoBeranda/:id',
    name: 'ProsesInfoBeranda',
    component: () => import('../../components/views/Backoffice/InfoBeranda/ProsesInfoBeranda.vue'),
    meta: {
      transition: "zoom",
    },
  },
  {
    path: '/DaerahIrigasi',
    name: 'DaerahIrigasi',
    component: () => import('../../components/views/Backoffice/DaerahIrigasi/DaerahIrigasi.vue'),
    meta: {
      transition: "zoom",
    },
  },
  {
    path: '/ProsesDaerahIrigasi/:id',
    name: 'ProsesDaerahIrigasi',
    component: () => import('../../components/views/Backoffice/DaerahIrigasi/ProsesDaerahIrigasi.vue'),
    meta: {
      transition: "zoom",
    },
  },
  {
    path: '/KategoriData',
    name: 'KategoriData',
    component: () => import('../../components/views/Backoffice/KategoriData/KategoriData.vue'),
    meta: {
      transition: "zoom",
    },
  },
  {
    path: '/ProsesKategoriData/:id',
    name: 'ProsesKategoriData',
    component: () => import('../../components/views/Backoffice/KategoriData/ProsesKategoriData.vue'),
    meta: {
      transition: "zoom",
    },
  },
  {
    path: '/APIPeta',
    name: 'APIPeta',
    component: () => import('../../components/views/Backoffice/APIPeta/APIPeta.vue'),
    meta: {
      transition: "zoom",
    },
  },
  {
    path: '/ProsesAPIPeta/:id',
    name: 'ProsesAPIPeta',
    component: () => import('../../components/views/Backoffice/APIPeta/ProsesAPIPeta.vue'),
    meta: {
      transition: "zoom",
    },
  },
  {
    path: '/LayerPeta',
    name: 'LayerPeta',
    component: () => import('../../components/views/Backoffice/LayerPeta/LayerPeta.vue'),
    meta: {
      transition: "zoom",
    },
  },
  {
    path: '/ProsesLayerPeta/:id',
    name: 'ProsesLayerPeta',
    component: () => import('../../components/views/Backoffice/LayerPeta/ProsesLayerPeta.vue'),
    meta: {
      transition: "zoom",
    },
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  mode: 'history',
  routes: routes,
  linkActiveClass: 'active',
})

export default router;
