<template>
    <div id="mySidenavLayer" class="sidenav p-0">
        <div class="card card-warning shadow-none">
            <div class="card-header" style="background-color: #013970 !important;color: #ffffff;">
                <h3 class="card-title text-white">Layer</h3>
                <!-- /.card-tools -->
                <div class="card-toolbar">
                    <button type="button" class="btn btn-warning float-right" @click="closeNavLayer()"><i
                            class="fa fa-times"></i></button>
                </div>
            </div>
            <div class="card-body">
                <h6><b id="judultransparant">Transparansi Layer</b></h6>
                <input id="opacity-input" type="range" min="0" max="1" step="0.01" value="0.75" style="width: 100%;"
                    class="slider" /><span id="opacity-output"></span>
            </div>
            <!-- /.card-header -->
            <div class="card-body" style="color: black !important;min-height: 80vh;">
                <h6><b id="daftarlayer">Daftar Layer</b></h6>
                <ul id="myUL">
                    <li><span class="caret">Layer Irigasi</span>
                        <ul class="nested">
                            <li> <span class="caret">Glapan Timur</span>
                                <ul class="nested">
                                    <li> <input type="checkbox">Saluran</li>
                                    <li> <input type="checkbox">Bangunan</li>
                                    <li> <input type="checkbox">Petak Tersier</li>
                                    <li> <input type="checkbox">P3A</li>
                                </ul>
                            </li>
                        </ul>
                    </li>
                </ul>
            </div>
        </div>
    </div>
    <Top />
    <!-- Spinner Start -->
    <div id="spinner" v-if="halamanloading"
        class="show bg-white position-fixed translate-middle w-100 vh-100 top-50 start-50 d-flex align-items-center justify-content-center">
        <div class="spinner-border text-danger" style="width: 3rem; height: 3rem;" role="status">
            <span class="sr-only">Loading...</span>
        </div>
        &nbsp;<img src="../../../../src/assets/img/disdag.png" height="40">
        &nbsp;<span class="text-danger">..Loading...</span>
    </div>
    <!-- Spinner End -->
    <!-- Header Start -->
    <div class="container-fluid bg-breadcrumb">
        <div class="container text-center py-5" style="max-width: 900px;">
            <h3 class="text-white display-3 mb-4 wow fadeInDown pmisputih" data-wow-delay="0.1s">
                PETA
            </h3>
            <ol class="breadcrumb justify-content-center mb-0 wow fadeInDown" data-wow-delay="0.3s">
                <li class="breadcrumb-item">
                    <a href="/">
                        Beranda
                    </a>
                </li>
                <li class="breadcrumb-item active text-warning">
                    WEB GIS
                </li>
            </ol>
        </div>
    </div>
    <!-- Header End -->
    <div class="container-fluid blog py-2">&nbsp;</div>
    <div class="content">
        <div class="container-fluid">
            <div class="row">
                <div class="col-sm-12 text-right px-2">
                    <div class="card cardputih shadow-none" style="padding-top: 0px !important;display: none;">
                        <div class="card-header px-5" style="background-color: aliceblue;">
                            <button type="button" data-toggle="tooltip" data-placement="left" title="Layer Peta"
                                @click="openNavLayer()" class="btn btn-danger3 btn-sm lebar2 text-white">
                                <b><i class="fa fa-copy"></i>&nbsp;
                                    <span id="judulpanellayer">Layer Peta</span>
                                </b>
                            </button>
                            <button type="button" data-toggle="tooltip" data-placement="left" title="Layer Peta"
                                onclick="openNavPencarian()" class="btn btn-info3 btn-sm lebar2 text-white"><b><i
                                        class="fas fa-search"></i>&nbsp;
                                    <span id="judulpanelcari">Pencarian</span></b></button>
                            <button type="button" data-toggle="tooltip" data-placement="left" title="Pengaturan Peta"
                                onclick="openNav()" class="btn btn-danger3 btn-sm lebar2 text-white"><b><i
                                        class="fa fa-wrench"></i>&nbsp;
                                    <span id="judulpanelpengaturan">Pengaturan</span></b></button>
                            <button type="button" data-toggle="tooltip" data-placement="left" title="Layer Peta"
                                onclick="openNavLegenda()" class="btn btn-info3 btn-sm lebar2 text-white"><b><i
                                        class="fas fa-sliders-h"></i>&nbsp;
                                    <span id="judulpanellegenda">Legenda</span></b></button>
                        </div>
                        <!-- /.card-header -->
                    </div>
                    <input type="hidden" class="form-control form-control-sm" id="detail" name="detail"
                        placeholder="Detail">
                    <input type="hidden" class="form-control form-control-sm" id="Latitude" name="Latitude"
                        placeholder="Latitude">
                    <input type="hidden" class="form-control form-control-sm" id="Longitude" name="Longitude"
                        placeholder="Longitude">
                    <button type="button" class="btn btn-tool" id="details" @click="details()" style="display: none;"><i
                            class="fas fa-minus text-white"></i></button>
                    <button type="button" class="btn btn-tool" id="kosong" @click="datakosong()"
                        style="display: none;"><i class="fas fa-minus text-white"></i></button>
                </div>
                <iframe :src="sourcenya" class="col-sm-12 text-center" style="min-height: 95vh;" frameborder="0"
                    id="maps" scrolling="no" @load="loadpeta()"></iframe>
            </div>
        </div>
    </div>
    <div class="container-fluid blog py-2">&nbsp;</div>
    <!-- Header End -->
    <a href="#" class="btn btn-dangerbulet btn-lg-square back-to-top text-white"><i class="fa fa-arrow-up"></i></a>
    <Footer />
</template>

<script>
import axios from 'axios';
import $ from 'jquery';
import swal from 'sweetalert2';
import CryptoJS from 'crypto-js';
import { useRoute } from 'vue-router';
import { watch } from 'vue';
import Top from "./Template/Top.vue";
import Footer from "./Template/Footer.vue";

export default {
    setup() {
        const route = useRoute()
        watch(() => route.name, () => {
        });

        return { route };
    },
    components: {
        Top, Footer, swal
    },
    data() {
        return {
            halamanloading: true,
            tahun: new Date().getFullYear(),
            linknya: process.env.VUE_APP_URL_API,
            sourcenya: '',
            sourcenyamapdetails: '',
            secretencData: decodeURIComponent(CryptoJS.AES.decrypt(this.route.params.id, 'oriproMop8LQ06S0rZXcJyEH3wk8jVrINbHwn7tBq2').toString(CryptoJS.enc.Utf8)),
        };
    },
    methods: {
        loadpeta() {
            this.halamanloading = true;
            const message = this.linknya + '|' + this.secretencData;
            const iframe = document.getElementById("maps");
            this.sourcenya = '';
            this.sourcenya = '/map.html';
            iframe.contentWindow.postMessage(message, "*");
            this.halamanloading = false;
        },
        openNavLayer() {
            if (document.getElementById("mySidenavLayer").style.width == "350px") {
                document.getElementById("mySidenavLayer").style.width = "0";
            } else {
                document.getElementById("mySidenavLayer").style.width = "350px";
            }
        },
        closeNavLayer() {
            document.getElementById("mySidenavLayer").style.width = "0";
        },
        datakosong() {
            swal.mixin({
                toast: true,
                position: 'top-end',
                showConfirmButton: false,
                timer: 3000
            }).fire({
                icon: 'warning',
                title: 'Data Layer Gagal Diunduh..',
            });
            $('.swal2-container').css("z-index", '999999');
        },
    },
    mounted() {
        this.halamanloading = true;
        var toggler = document.getElementsByClassName("caret");
        var i;

        for (i = 0; i < toggler.length; i++) {
            toggler[i].addEventListener("click", function () {
                this.parentElement.querySelector(".nested").classList.toggle("active");
                this.classList.toggle("caret-down");
            });
        }
    },
}
</script>

<style></style>