<template>
    <Top />
    <!-- Spinner Start -->
    <div id="spinner" v-if="halamanloading"
        class="show bg-white position-fixed translate-middle w-100 vh-100 top-50 start-50 d-flex align-items-center justify-content-center">
        <div class="spinner-border text-danger" style="width: 3rem; height: 3rem;" role="status">
            <span class="sr-only">Loading...</span>
        </div>
        &nbsp;<img src="../../../../src/assets/img/disdag.png" height="40">
        &nbsp;<span class="text-danger">..Loading...</span>
    </div>
    <!-- Spinner End -->
    <!-- Header Start -->
    <div class="container-fluid bg-breadcrumb">
        <div class="container text-center py-5" style="max-width: 900px;">
            <h3 class="text-white display-3 mb-4 wow fadeInDown pmisputih" data-wow-delay="0.1s">
                DAFTAR DAERAH IRIGASI
            </h3>
            <ol class="breadcrumb justify-content-center mb-0 wow fadeInDown" data-wow-delay="0.3s">
                <li class="breadcrumb-item">
                    <a href="/">
                        Beranda
                    </a>
                </li>
                <li class="breadcrumb-item active text-warning">
                    Daerah Irigasi
                </li>
            </ol>
        </div>
    </div>
    <!-- Header End -->
    <!-- Contact Start -->
    <div class="container-fluid blog py-2">
        &nbsp;
    </div>
    <div class="container-fluid bg-breadcrumbberanda px-5 wow fadeInDown">
        <div class="card collapsed-card">
            <div class="card-header" data-card-widget="collapse" style="cursor: pointer;">
                <h5 class="card-title">Filter Data</h5>

                <div class="card-tools">
                    <button type="button" class="btn btn-tool" data-card-widget="collapse">
                        <i class="fas fa-plus"></i>
                    </button>
                </div>
            </div>
            <!-- /.card-header -->
            <div class="card-body">
                <div class="row">
                    <div class="col-sm-4">
                        <label>Pilihan Kewenangan </label>
                        <v-select :options="kewenangan" :reduce="(label) => label.code" label="label"
                            v-model="defaultSelectedkewenangan" @update:modelValue="onkewenanganChange"
                            style="background-color: #ffffff;"></v-select>
                    </div>
                    <div class="col-sm-4">
                        <label>Pilihan Daerah Irigasi </label>
                        <v-select :options="kodeDI" :reduce="(label) => label.code" label="label"
                            v-model="defaultSelectedkodeDI" @update:modelValue="onkodeDIChange"
                            style="background-color: #ffffff;"></v-select>
                    </div>
                    <div class="col-sm-4">
                        <label>Pilihan Balai </label>
                        <v-select :options="balai" :reduce="(label) => label.code" label="label"
                            v-model="defaultSelectedbalai" @update:modelValue="onbalaiChange"
                            style="background-color: #ffffff;"></v-select>
                    </div>
                </div>
                <!-- /.row -->
            </div>
            <!-- ./card-body -->
            <div class="card-footer">

            </div>
            <!-- /.card-footer -->
        </div>
    </div>
    <div class="container-fluid bg-breadcrumbberanda px-5 wow fadeInUp">
        <div class="card">
            <div class="card-header" data-card-widget="collapse" style="cursor: pointer;">
                <h5 class="card-title">Infografis Daerah Irigasi</h5>

                <div class="card-tools">
                    <button type="button" class="btn btn-tool" data-card-widget="collapse">
                        <i class="fas fa-minus"></i>
                    </button>
                </div>
            </div>
            <!-- /.card-header -->
            <div class="card-body">
                <div class="row px-2">
                    <div class="col-sm-3 col-6 bg-white">
                        <div class="description-block border-right">
                            <span class="description-percentage text-success"> TOTAL LUAS D.I</span>
                            <h5 class="description-header">{{ G_numFormatKoma(totalluasdaerahirigasi) }}</h5>
                            <span class="description-text">Ha</span>
                        </div>
                        <!-- /.description-block -->
                    </div>
                    <!-- /.col -->
                    <div class="col-sm-3 col-6 bg-white">
                        <div class="description-block border-right">
                            <span class="description-percentage text-warning">RATA RATA LUAS D.I</span>
                            <h5 class="description-header">{{ G_numFormatKoma(luasmindaerahirigasi) }}</h5>
                            <span class="description-text">Ha</span>
                        </div>
                        <!-- /.description-block -->
                    </div>
                    <!-- /.col -->
                    <div class="col-sm-3 col-6 bg-white">
                        <div class="description-block border-right">
                            <span class="description-percentage text-success">IP3A BERBADAN HUKUM</span>
                            <h5 class="description-header">{{ G_numFormat(berlembaga) }}</h5>
                            <span class="description-text">Unit</span>
                        </div>
                        <!-- /.description-block -->
                    </div>
                    <!-- /.col -->
                    <div class="col-sm-3 col-6 bg-white">
                        <div class="description-block">
                            <span class="description-percentage text-danger">IP3A TIDAK BERBADAN HUKUM</span>
                            <h5 class="description-header">{{ G_numFormat(takberlembaga) }} </h5>
                            <span class="description-text">Unit</span>
                        </div>
                        <!-- /.description-block -->
                    </div>
                    <div class="col-sm-3 col-6 bg-white">
                        <div class="description-block border-right">
                            <span class="description-percentage text-success">GP3A BERBADAN HUKUM</span>
                            <h5 class="description-header">{{ G_numFormat(gberlembaga) }}</h5>
                            <span class="description-text">Unit</span>
                        </div>
                        <!-- /.description-block -->
                    </div>
                    <!-- /.col -->
                    <div class="col-sm-3 col-6 bg-white">
                        <div class="description-block">
                            <span class="description-percentage text-danger">GP3A TIDAK BERBADAN HUKUM</span>
                            <h5 class="description-header">{{ G_numFormat(gtakberlembaga) }} </h5>
                            <span class="description-text">Unit</span>
                        </div>
                        <!-- /.description-block -->
                    </div>
                    <div class="col-sm-3 col-6 bg-white">
                        <div class="description-block border-right">
                            <span class="description-percentage text-success">P3A BERBADAN HUKUM</span>
                            <h5 class="description-header">{{ G_numFormat(pberlembaga) }}</h5>
                            <span class="description-text">Unit</span>
                        </div>
                        <!-- /.description-block -->
                    </div>
                    <!-- /.col -->
                    <div class="col-sm-3 col-6 bg-white">
                        <div class="description-block">
                            <span class="description-percentage text-danger">P3A TIDAK BERBADAN HUKUM</span>
                            <h5 class="description-header">{{ G_numFormat(ptakberlembaga) }} </h5>
                            <span class="description-text">Unit</span>
                        </div>
                        <!-- /.description-block -->
                    </div>
                </div>
                <div class="row px-2">
                    <div class="col-12 col-sm-12 col-md-12">&nbsp;</div>
                </div>
                <div class="row px-2">
                    <div class="col-12 col-sm-6 col-md-4">
                        <div class="info-box">
                            <span class="info-box-icon bg-green elevation-1"><i class="fas fa-vector-square"></i></span>
                            <div class="info-box-content">
                                <span class="info-box-text">D.I Kewenangan Pusat</span>
                                <span class="info-box-number">
                                    {{ G_numFormat(KewenanganPusat) }}
                                    <small>Data</small>
                                </span>
                            </div>
                            <!-- /.info-box-content -->
                        </div>
                        <!-- /.info-box -->
                    </div>
                    <div class="col-12 col-sm-6 col-md-4">
                        <div class="info-box">
                            <span class="info-box-icon bg-pink elevation-1"><i class="fas fa-vector-square"></i></span>
                            <div class="info-box-content">
                                <span class="info-box-text">D.I Kewenangan Provinsi</span>
                                <span class="info-box-number"> {{ G_numFormat(KewenanganProvinsi) }}
                                    <small>Data</small>
                                </span>
                            </div>
                            <!-- /.info-box-content -->
                        </div>
                        <!-- /.info-box -->
                    </div>

                    <div class="col-12 col-sm-6 col-md-4">
                        <div class="info-box">
                            <span class="info-box-icon bg-purple elevation-1"><i
                                    class="fas fa-vector-square"></i></span>
                            <div class="info-box-content">
                                <span class="info-box-text">D.I Kewenangan Kabupaten/Kota</span>
                                <span class="info-box-number"> {{ G_numFormat(KewenanganKab) }}
                                    <small>Data</small>
                                </span>
                            </div>
                            <!-- /.info-box-content -->
                        </div>
                        <!-- /.info-box -->
                    </div>
                    <div class="col-12 col-sm-6 col-md-4">
                        <div class="info-box">
                            <span class="info-box-icon bg-primary elevation-1"><i class="fas fa-icicles"></i></span>
                            <div class="info-box-content">
                                <span class="info-box-text">Jumlah Daerah Irigasi</span>
                                <span class="info-box-number">
                                    {{ jumlahdaerahirigasi }}
                                    <small>Area</small>
                                </span>
                            </div>
                            <!-- /.info-box-content -->
                        </div>
                        <!-- /.info-box -->
                    </div>
                    <div class="col-12 col-sm-6 col-md-4">
                        <div class="info-box">
                            <span class="info-box-icon bg-info elevation-1"><i class="fab fa-think-peaks"></i></span>
                            <div class="info-box-content">
                                <span class="info-box-text">Daerah Irigasi Terluas</span>
                                <span class="info-box-number"> {{ namamaxdaerahirigasi }}
                                    : {{ G_numFormatKoma(luasmaxdaerahirigasi) }}
                                    <small>Ha</small>
                                </span>
                            </div>
                            <!-- /.info-box-content -->
                        </div>
                        <!-- /.info-box -->
                    </div>
                    <div class="col-12 col-sm-6 col-md-4">
                        <div class="info-box">
                            <span class="info-box-icon bg-red elevation-1"><i class="fab fa-viacoin"></i></span>
                            <div class="info-box-content">
                                <span class="info-box-text">Daerah Irigasi Terkecil</span>
                                <span class="info-box-number"> {{ namamindaerahirigasi }}
                                    : {{ G_numFormatKoma(luasmindaerahirigasi) }}
                                    <small>Ha</small>
                                </span>
                            </div>
                            <!-- /.info-box-content -->
                        </div>
                        <!-- /.info-box -->
                    </div>
                    <div class="col-12 col-sm-6 col-md-4">
                        <div class="info-box">
                            <span class="info-box-icon bg-fuchsia elevation-1"><i class="fas fa-user-check"></i></span>
                            <div class="info-box-content">
                                <span class="info-box-text">Total IP3A</span>
                                <span class="info-box-number"> {{ G_numFormat(TotalIP3A) }}
                                    <small>Unit</small>
                                </span>
                            </div>
                            <!-- /.info-box-content -->
                        </div>
                        <!-- /.info-box -->
                    </div>
                    <div class="col-12 col-sm-6 col-md-4">
                        <div class="info-box">
                            <span class="info-box-icon bg-cyan elevation-1"><i class="fas fa-user-shield"></i></span>
                            <div class="info-box-content">
                                <span class="info-box-text">Total GP3A</span>
                                <span class="info-box-number"> {{ G_numFormat(TotalGP3A) }}
                                    <small>Unit</small>
                                </span>
                            </div>
                            <!-- /.info-box-content -->
                        </div>
                        <!-- /.info-box -->
                    </div>
                    <div class="col-12 col-sm-6 col-md-4">
                        <div class="info-box">
                            <span class="info-box-icon bg-gray elevation-1"><i class="fas fa-user-tie"></i></span>
                            <div class="info-box-content">
                                <span class="info-box-text">Total P3A</span>
                                <span class="info-box-number"> {{ G_numFormat(TotalP3A) }}
                                    <small>Unit</small>
                                </span>
                            </div>
                            <!-- /.info-box-content -->
                        </div>
                        <!-- /.info-box -->
                    </div>
                    <div class="col-12 col-sm-12 col-md-12">
                        <apexchart height="350" :options="chartOptions" :series="series">
                        </apexchart>
                    </div>
                    <div class="col-12 col-sm-12 col-md-12">&nbsp;</div>
                </div>

            </div>
            <!-- ./card-body -->
            <div class="card-footer">

            </div>
            <!-- /.card-footer -->
        </div>
    </div>
    <div class="container-fluid bg-breadcrumbberanda px-5">
        <div class="card">
            <div class="card-header" data-card-widget="collapse" style="cursor: pointer;">
                <h5 class="card-title">Daftar Daerah Irigasi</h5>

                <div class="card-tools">
                    <button type="button" class="btn btn-tool" data-card-widget="collapse">
                        <i class="fas fa-minus"></i>
                    </button>
                </div>
            </div>
            <!-- /.card-header -->
            <div class="card-body">
                <div class="row">
                    <div class="col-sm-12 float-left pb-2 px-3">
                        <div class="card card-warning collapsed-card">
                            <div class="card-header" data-card-widget="collapse" style="cursor: pointer;">
                                <h3 class="card-title">Show / Hide Kolom</h3>
                                <div class="card-tools">
                                    <button type="button" class="btn btn-tool" data-card-widget="collapse"><i
                                            class="fas fa-plus" style="color: #ffffff;"></i>
                                    </button>
                                </div>
                            </div>
                            <div class="card-body">
                                <div class="card">
                                    <div class="card-body">
                                        <div class="row">
                                            <div class="col-lg-2 col-12">
                                                <div class="custom-control custom-checkbox">
                                                    <input class="custom-control-input custom-control-input-warning"
                                                        type="checkbox" id="customCheckboxKode" value="optionKode"
                                                        @click="showkode()">
                                                    <label for="customCheckboxKode" class="custom-control-label">
                                                        Kode</label>
                                                </div>
                                            </div>
                                            <div class="col-lg-2 col-12">
                                                <div class="custom-control custom-checkbox">
                                                    <input class="custom-control-input custom-control-input-warning"
                                                        type="checkbox" id="customCheckboxLuas" value="optionLuas"
                                                        @click="showluas()">
                                                    <label for="customCheckboxLuas" class="custom-control-label">
                                                        Luas</label>
                                                </div>
                                            </div>
                                            <div class="col-lg-2 col-12">
                                                <div class="custom-control custom-checkbox">
                                                    <input class="custom-control-input custom-control-input-warning"
                                                        type="checkbox" id="customCheckboxBalai" value="optionBalai"
                                                        @click="showbalai()">
                                                    <label for="customCheckboxBalai" class="custom-control-label">
                                                        Balai</label>
                                                </div>
                                            </div>
                                            <div class="col-lg-2 col-12">
                                                <div class="custom-control custom-checkbox">
                                                    <input class="custom-control-input custom-control-input-warning"
                                                        type="checkbox" id="customCheckboxKewenangan"
                                                        value="optionKewenangan" @click="showkewenangan()">
                                                    <label for="customCheckboxKewenangan" class="custom-control-label">
                                                        Kewenangan</label>
                                                </div>
                                            </div>
                                            <div class="col-lg-2 col-12">
                                                <div class="custom-control custom-checkbox">
                                                    <input class="custom-control-input custom-control-input-warning"
                                                        type="checkbox" id="customCheckboxInfrastruktur"
                                                        value="optionInfrastruktur"
                                                        @click="showColumnInfrastruktur = !showColumnInfrastruktur">
                                                    <label for="customCheckboxInfrastruktur"
                                                        class="custom-control-label">
                                                        Infrastruktur</label>
                                                </div>
                                            </div>
                                            <div class="col-lg-2 col-12">
                                                <div class="custom-control custom-checkbox">
                                                    <input class="custom-control-input custom-control-input-warning"
                                                        type="checkbox" id="customCheckboxIP3A" value="optionIP3A"
                                                        checked @click="showIP3A()">
                                                    <label for="customCheckboxIP3A" class="custom-control-label">
                                                        IP3A</label>
                                                </div>
                                            </div>
                                            <div class="col-lg-2 col-12">
                                                <div class="custom-control custom-checkbox">
                                                    <input class="custom-control-input custom-control-input-warning"
                                                        type="checkbox" id="customCheckboxGP3A" value="optionGP3A"
                                                        checked @click="showGP3A()">
                                                    <label for="customCheckboxGP3A" class="custom-control-label">
                                                        GP3A</label>
                                                </div>
                                            </div>
                                            <div class="col-lg-2 col-12">
                                                <div class="custom-control custom-checkbox">
                                                    <input class="custom-control-input custom-control-input-warning"
                                                        type="checkbox" id="customCheckboxP3A" value="optionP3A" checked
                                                        @click="showP3A()">
                                                    <label for="customCheckboxP3A" class="custom-control-label">
                                                        P3A</label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-12 float-left pb-2 px-3 wow fadeInDown">
                        Jumlah Data: {{ G_numFormat(jumlahdata) }}
                        <span style="float: right;">
                            Halaman ke: <select v-model="halaman" @change="onChangeHalaman($event)">
                                <option v-for="index in jumlahhalaman" :key="index">
                                    {{ index }}</option>
                            </select> dari {{ jumlahhalaman }}
                        </span>
                    </div>
                    <div class="col-sm-12 float-left pb-2 wow fadeInDown">
                        <input type="text" v-model="carikata" class="form-control form-control-sm"
                            placeholder="Masukkan Kata Kunci Pencarian Halaman Terpilih">
                    </div>
                    <div class="table-responsive table-wrapper">
                        <table class="table m-0" id="tabeldata">
                            <thead>
                                <tr>
                                    <th style="width: 50px;" rowspan="4">No</th>
                                    <th style="width: 50px;" rowspan="4" v-if="showColumnKode">Kode<br>Daerah Irigasi</th>
                                    <th style="width: 100px;" rowspan="4">Nama<br>Daerah Irigasi</th>
                                    <th style="width: 60px;" rowspan="4" v-if="showColumnLuas">Luas<br>(Ha)</th>
                                    <th style="width: 100px;" rowspan="4" v-if="showColumnBalai">Nama<br>Balai</th>
                                    <th style="width: 100px;" rowspan="4" v-if="showColumnKewenangan">Kewenangan</th>
                                    <th style="width: 100px;" colspan="3" v-if="showColumnInfrastruktur">Jumlah
                                        Infrastruktur<br>(buah)</th>
                                    <th style="width: 100px;" :colspan="colspan2" v-if="showColumnIP3A || showColumnGP3A || showColumnP3A">Kelembagaan<br>(unit)</th>
                                </tr>
                                <tr>
                                    <th style="width: 50px;" rowspan="3" v-if="showColumnInfrastruktur">Saluran</th>
                                    <th style="width: 50px;" rowspan="3" v-if="showColumnInfrastruktur">Bangunan</th>
                                    <th style="width: 50px;" rowspan="3" v-if="showColumnInfrastruktur">Petak<br>Tersier
                                    </th>
                                    <th style="width: 50px;" colspan="7" v-if="showColumnIP3A">IP3A</th>
                                    <th style="width: 50px;" colspan="7" v-if="showColumnGP3A">GP3A</th>
                                    <th style="width: 50px;" colspan="7" v-if="showColumnP3A">P3A</th>
                                </tr>
                                <tr>
                                    <th style="width: 50px;" rowspan="2" v-if="showColumnIP3A">Total</th>
                                    <th style="width: 50px;" colspan="5" v-if="showColumnIP3A">Kategori Evaluasi<br>Kinerja</th>
                                    <th style="width: 50px;" rowspan="2" v-if="showColumnIP3A">Belum<br>Evaluasi<br>Kinerja</th>
                                    <th style="width: 50px;" rowspan="2" v-if="showColumnGP3A">Total</th>
                                    <th style="width: 50px;" colspan="5" v-if="showColumnGP3A">Kategori Evaluasi<br>Kinerja</th>
                                    <th style="width: 50px;" rowspan="2" v-if="showColumnGP3A">Belum<br>Evaluasi<br>Kinerja</th>
                                    <th style="width: 50px;" rowspan="2" v-if="showColumnP3A">Total</th>
                                    <th style="width: 50px;" colspan="5" v-if="showColumnP3A">Kategori Evaluasi<br>Kinerja</th>
                                    <th style="width: 50px;" rowspan="2" v-if="showColumnP3A">Belum<br>Evaluasi<br>Kinerja</th>
                                </tr>
                                <tr>
                                    <th style="width: 50px;" v-if="showColumnIP3A">Jml</th>
                                    <th style="width: 50px;" v-if="showColumnIP3A">BB</th>
                                    <th style="width: 50px;" v-if="showColumnIP3A">SB</th>
                                    <th style="width: 50px;" v-if="showColumnIP3A">B</th>
                                    <th style="width: 50px;" v-if="showColumnIP3A">M</th>
                                    <th style="width: 50px;" v-if="showColumnGP3A">Jml</th>
                                    <th style="width: 50px;" v-if="showColumnGP3A">BB</th>
                                    <th style="width: 50px;" v-if="showColumnGP3A">SB</th>
                                    <th style="width: 50px;" v-if="showColumnGP3A">B</th>
                                    <th style="width: 50px;" v-if="showColumnGP3A">M</th>
                                    <th style="width: 50px;" v-if="showColumnP3A">Jml</th>
                                    <th style="width: 50px;" v-if="showColumnP3A">BB</th>
                                    <th style="width: 50px;" v-if="showColumnP3A">SB</th>
                                    <th style="width: 50px;" v-if="showColumnP3A">B</th>
                                    <th style="width: 50px;" v-if="showColumnP3A">M</th>
                                </tr>
                                <tr>
                                    <td class="levelsubkegiatan" :colspan="colspan1"> TOTAL</td>
                                    <td class="levelsubkegiatan text-center" v-if="showColumnInfrastruktur">
                                        {{ G_numFormat(totalsaluran) }}
                                    </td>
                                    <td class="levelsubkegiatan text-center" v-if="showColumnInfrastruktur">
                                        {{ G_numFormat(totalbangunan) }}
                                    </td>
                                    <td class="levelsubkegiatan text-center" v-if="showColumnInfrastruktur">
                                        {{ G_numFormat(totalpetak) }}
                                    </td>
                                    <td class="levelsubkegiatan text-center" v-if="showColumnIP3A">
                                        {{ G_numFormat(TotalIP3A) }}
                                    </td>
                                    <td class="levelsubkegiatan text-center" v-if="showColumnIP3A">
                                        {{ G_numFormat(TotalIP3Aevkin) }}
                                    </td>
                                    <td v-if="showColumnIP3A" class="levelsubkegiatan text-center"
                                        v-for="(datalist, urutlist) in klasifikasi" :key="urutlist">
                                        {{ G_numFormat(datalist.JumlahIP3A) }}
                                    </td>
                                    <td class="levelsubkegiatan text-center" v-if="showColumnGP3A">
                                        {{ G_numFormat(TotalGP3A) }}
                                    </td>
                                    <td class="levelsubkegiatan text-center" v-if="showColumnGP3A">
                                        {{ G_numFormat(TotalGP3Aevkin) }}
                                    </td>
                                    <td v-if="showColumnGP3A" class="levelsubkegiatan text-center"
                                        v-for="(datalist, urutlist) in klasifikasi" :key="urutlist">
                                        {{ G_numFormat(datalist.JumlahGP3A) }}
                                    </td>
                                    <td class="levelsubkegiatan text-center" v-if="showColumnP3A">
                                        {{ G_numFormat(TotalP3A) }}
                                    </td>
                                    <td class="levelsubkegiatan text-center" v-if="showColumnP3A">
                                        {{ G_numFormat(TotalP3Aevkin) }}
                                    </td>
                                    <td v-if="showColumnP3A" class="levelsubkegiatan text-center"
                                        v-for="(datalist, urutlist) in klasifikasi" :key="urutlist">
                                        {{ G_numFormat(datalist.JumlahP3A) }}
                                    </td>
                                </tr>
                            </thead>
                            <template v-for="(datalist, urutlist) in filteredKataData" :key="urutlist">
                                <tbody>
                                    <tr>
                                        <td class="text-center">{{ urutlist + 1 }}</td>
                                        <td v-if="showColumnKode">
                                            {{ datalist.KodeDI }}
                                        </td>
                                        <td>
                                            {{ datalist.NamaDI }}
                                        </td>
                                        <td class="text-right" v-if="showColumnLuas">
                                            {{ G_numFormatKoma(datalist.LuasHa) }}
                                        </td>
                                        <td v-if="showColumnBalai">
                                            {{ datalist.NmBalai }}
                                        </td>
                                        <td v-if="showColumnKewenangan">
                                            {{ datalist.NamaKewenangan }}
                                        </td>
                                        <td class="text-center" v-if="showColumnInfrastruktur">
                                            {{ G_numFormat(datalist.JumlahSaluran) }}
                                        </td>
                                        <td class="text-center" v-if="showColumnInfrastruktur">
                                            {{ G_numFormat(datalist.JumlahBangunan) }}
                                        </td>
                                        <td class="text-center" v-if="showColumnInfrastruktur">
                                            {{ G_numFormat(datalist.JumlahPetak) }}
                                        </td>
                                        <td class="text-center" v-if="showColumnIP3A">
                                            {{ G_numFormat(datalist.JumlahIP3A) }}
                                        </td>
                                        <td class="text-center" v-if="showColumnIP3A">
                                            {{ G_numFormat(datalist.JumlahIP3Asudahevkin) }}
                                        </td>
                                        <td v-if="showColumnIP3A" class="text-center"
                                            v-for="(DataKlasifikasiEvkinP3A, urutDataKlasifikasiEvkinP3A) in datalist.DataKlasifikasiEvkinP3A"
                                            :key="urutDataKlasifikasiEvkinP3A">
                                            {{ G_numFormat(DataKlasifikasiEvkinP3A.JumlahIP3A) }}
                                        </td>
                                        <td class="text-center" v-if="showColumnGP3A">
                                            {{ G_numFormat(datalist.JumlahGP3A) }}
                                        </td>
                                        <td class="text-center" v-if="showColumnGP3A">
                                            {{ G_numFormat(datalist.JumlahGP3Asudahevkin) }}
                                        </td>
                                        <td v-if="showColumnGP3A" class="text-center"
                                            v-for="(DataKlasifikasiEvkinP3A, urutDataKlasifikasiEvkinP3A) in datalist.DataKlasifikasiEvkinP3A"
                                            :key="urutDataKlasifikasiEvkinP3A">
                                            {{ G_numFormat(DataKlasifikasiEvkinP3A.JumlahGP3A) }}
                                        </td>
                                        <td class="text-center" v-if="showColumnP3A">
                                            {{ G_numFormat(datalist.JumlahP3A) }}
                                        </td>
                                        <td class="text-center" v-if="showColumnP3A">
                                            {{ G_numFormat(datalist.JumlahP3Asudahevkin) }}
                                        </td>
                                        <td v-if="showColumnP3A" class="text-center"
                                            v-for="(DataKlasifikasiEvkinP3A, urutDataKlasifikasiEvkinP3A) in datalist.DataKlasifikasiEvkinP3A"
                                            :key="urutDataKlasifikasiEvkinP3A">
                                            {{ G_numFormat(DataKlasifikasiEvkinP3A.JumlahP3A) }}
                                        </td>
                                    </tr>
                                </tbody>
                            </template>
                        </table>
                    </div>
                </div>
                <!-- /.row -->
            </div>
            <!-- ./card-body -->
            <div class="card-footer">

            </div>
            <!-- /.card-footer -->
        </div>
    </div>
    <div class="col-sm-12">&nbsp;</div>
    <!-- Contact End -->
    <a href="#" class="btn btn-dangerbulet btn-lg-square back-to-top text-white"><i class="fa fa-arrow-up"></i></a>
    <Footer />
</template>

<script>
import axios from 'axios';
import $ from 'jquery';
import swal from 'sweetalert2';
import vSelect from 'vue-select';
import VueApexCharts from "vue3-apexcharts";
window.Apex.chart = { fontFamily: "Poppins-SemiBold, Arial, sans-serif" };
import Top from "./Template/Top.vue";
import Footer from "./Template/Footer.vue";

export default {
    components: {
        Top, Footer, swal, vSelect, apexchart: VueApexCharts,
    },
    data() {
        return {
            halamanloading: true,
            tahun: new Date().getFullYear(),
            bahasa: localStorage.getItem("bahasasistem"),
            datapesan: '',
            datamaster: [],
            klasifikasi: [],
            carikata: '',
            jumlahdaerahirigasi: 0,
            namamindaerahirigasi: '',
            luasmindaerahirigasi: 0,
            namamaxdaerahirigasi: '',
            luasmaxdaerahirigasi: 0,
            totalluasdaerahirigasi: 0,
            rataluasdaerahirigasi: 0,
            berlembaga: 0,
            takberlembaga: 0,
            gberlembaga: 0,
            gtakberlembaga: 0,
            pberlembaga: 0,
            ptakberlembaga: 0,
            KewenanganPusat: 0,
            KewenanganProvinsi: 0,
            KewenanganKab: 0,
            TotalIP3A: 0,
            TotalIP3Aevkin: 0,
            TotalGP3A: 0,
            TotalGP3Aevkin: 0,
            TotalP3A: 0,
            TotalP3Aevkin: 0,
            jumlahdata: 0,
            jumlahhalaman: 0,
            mulai: 0,
            halaman: 1,
            kewenangan: [],
            datakewenangan: [],
            defaultSelectedkewenangan: {
                code: 0,
                label: 'Semua Data',
                tag: '',
            },
            selectedkewenangan: '',
            kodeDI: [],
            datakodeDI: [],
            defaultSelectedkodeDI: {
                code: 0,
                label: 'Semua Data',
                tag: '',
            },
            selectedkodeDI: '',
            balai: [],
            databalai: [],
            defaultSelectedbalai: {
                code: 0,
                label: 'Semua Data',
                tag: '',
            },
            selectedbalai: '',
            totalsaluran: 0,
            totalbangunan: 0,
            totalpetak: 0,
            showColumnKode: false,
            showColumnLuas: false,
            showColumnBalai: false,
            showColumnKewenangan: false,
            showColumnInfrastruktur: false,
            colspan1: 2,
            colspan2: 21,
            showColumnIP3A: true,
            showColumnGP3A: true,
            showColumnP3A: true,
            series: [],
            chartOptions: {
                colors: [
                    '#f9a867',
                    '#6a6ba5',
                    '#6c98b7',
                    '#2a6b9b',
                    '#d5e2eb',
                ],
                chart: {
                    type: 'bar',
                    height: 350,
                    stacked: true,
                    toolbar: {
                        show: true
                    },
                    zoom: {
                        enabled: true
                    }
                },
                responsive: [{
                    breakpoint: 480,
                    options: {
                        legend: {
                            position: 'bottom',
                            offsetX: -10,
                            offsetY: 0
                        }
                    }
                }],
                plotOptions: {
                    bar: {
                        horizontal: false,
                        borderRadius: 10,
                        borderRadiusApplication: 'end', // 'around', 'end'
                        borderRadiusWhenStacked: 'last', // 'all', 'last'
                        dataLabels: {
                            total: {
                                enabled: true,
                                style: {
                                    fontSize: '13px',
                                    fontWeight: 900
                                }
                            }
                        }
                    },
                },
                xaxis: {
                    type: 'text',
                    categories: ['IP3A', 'GP3A', 'P3A'],
                },
                legend: {
                    position: 'right',
                    offsetY: 40
                },
                fill: {
                    opacity: 1
                }
            },
        };
    },
    computed: {
        filteredKataData() {
            const filteredWorkers = this.carikata === ""
                ? this.datamaster
                : this.datamaster.filter(wo => Object.values(wo).join("").toLowerCase().indexOf(this.carikata.toLowerCase()) !== -1);
            return filteredWorkers;
        },
        JumlahfilteredKataData() {
            var jumlah = 0;
            try {
                if (this.filteredKataData === undefined) {
                    jumlah = 0;
                } else {
                    jumlah = this.filteredKataData.length;
                }
            }
            catch {
                jumlah = 0;
            }
            return jumlah;
        },
    },
    methods: {
        showkode() {
            this.showColumnKode = !this.showColumnKode;
            if (this.showColumnKode == true) {
                this.colspan1 = this.colspan1 + 1;
            }
            else {
                this.colspan1 = this.colspan1 - 1;
            }
        },
        showluas() {
            this.showColumnLuas = !this.showColumnLuas;
            if (this.showColumnLuas == true) {
                this.colspan1 = this.colspan1 + 1;
            }
            else {
                this.colspan1 = this.colspan1 - 1;
            }
        },
        showbalai() {
            this.showColumnBalai = !this.showColumnBalai;
            if (this.showColumnBalai == true) {
                this.colspan1 = this.colspan1 + 1;
            }
            else {
                this.colspan1 = this.colspan1 - 1;
            }
        },
        showkewenangan() {
            this.showColumnKewenangan = !this.showColumnKewenangan;
            if (this.showColumnKewenangan == true) {
                this.colspan1 = this.colspan1 + 1;
            }
            else {
                this.colspan1 = this.colspan1 - 1;
            }
        },
        showIP3A() {
            this.showColumnIP3A = !this.showColumnIP3A;
            if (this.showColumnIP3A == true) {
                this.colspan2 = this.colspan2 + 7;
            }
            else {
                this.colspan2 = this.colspan2 - 7;
            }
        },
        showGP3A() {
            this.showColumnGP3A = !this.showColumnGP3A;
            if (this.showColumnGP3A == true) {
                this.colspan2 = this.colspan2 + 7;
            }
            else {
                this.colspan2 = this.colspan2 - 7;
            }
        },
        showP3A() {
            this.showColumnP3A = !this.showColumnP3A;
            if (this.showColumnP3A == true) {
                this.colspan2 = this.colspan2 + 7;
            }
            else {
                this.colspan2 = this.colspan2 - 7;
            }
        },
        async ambildataReffPublik() {
            this.halamanloading = true;
            const mainAPIReFF = axios.create({
                baseURL: process.env.VUE_APP_URL_API,
                headers: {
                    Authorization: "Bearer GPMop8LQ06S0rZXcJyEH3wk8jVrINbHwn7tBq2",
                },
            });
            var random = Math.random();
            await mainAPIReFF.get("parastapainnovation_p-GetKewenangan?random=" + random).then(
                Response => {
                    this.datapesan = Response.data.message;
                    if (this.datapesan == 'data diketemukan') {
                        this.datakewenangan = [];
                        this.kewenangan = [];
                        this.datakewenangan = Response.data.content.data;
                        this.datakewenangan.forEach((item) => {
                            this.kewenangan.push({
                                label: item.nama,
                                code: this.datakewenangan.indexOf(item) + 1,
                                tag: item.id,
                            });
                        });
                        this.kewenangan.unshift({
                            label: 'Semua Data',
                            code: 0,
                            tag: '',
                        });
                    }
                    else {
                        this.datakewenangan = [];
                        this.kewenangan = [];
                        this.kewenangan.unshift({
                            label: 'Semua Data',
                            code: 0,
                            tag: '',
                        });
                    }
                    this.halamanloading = false;
                }
            ).catch(error => {
                swal.fire('Peringatan', 'Kesalahan fecthing data', 'error');
                this.halamanloading = false;
                return false;
            })
            await mainAPIReFF.get("parastapainnovation_p-GetDI?random=" + random + "&kewenangan=" + this.selectedkewenangan).then(
                Response => {
                    this.datapesan = Response.data.message;
                    if (this.datapesan == 'data diketemukan') {
                        this.datakodeDI = [];
                        this.kodeDI = [];
                        this.datakodeDI = Response.data.content.data;
                        this.datakodeDI.forEach((item) => {
                            this.kodeDI.push({
                                label: '[' + item.kode + '] ' + item.nama,
                                code: this.datakodeDI.indexOf(item) + 1,
                                tag: item.kode,
                            });
                        });
                        this.kodeDI.unshift({
                            label: 'Semua Data',
                            code: 0,
                            tag: '',
                        });
                    }
                    else {
                        this.datakodeDI = [];
                        this.kodeDI = [];
                        this.kodeDI.unshift({
                            label: 'Semua Data',
                            code: 0,
                            tag: '',
                        });
                    }
                    this.halamanloading = false;
                }
            ).catch(error => {
                swal.fire('Peringatan', 'Kesalahan fecthing data', 'error');
                this.halamanloading = false;
                return false;
            })
            await mainAPIReFF.get("parastapainnovation_p-GetBalai?random=" + random + "&kewenangan=" + this.selectedkewenangan).then(
                Response => {
                    this.datapesan = Response.data.message;
                    if (this.datapesan == 'data diketemukan') {
                        this.databalai = [];
                        this.balai = [];
                        this.databalai = Response.data.content.data;
                        this.databalai.forEach((item) => {
                            this.balai.push({
                                label: item.nama,
                                code: this.databalai.indexOf(item) + 1,
                                tag: item.id,
                            });
                        });
                        this.balai.unshift({
                            label: 'Semua Data',
                            code: 0,
                            tag: '',
                        });
                    }
                    else {
                        this.databalai = [];
                        this.balai = [];
                        this.balai.unshift({
                            label: 'Semua Data',
                            code: 0,
                            tag: '',
                        });
                    }
                    this.halamanloading = false;
                }
            ).catch(error => {
                swal.fire('Peringatan', 'Kesalahan fecthing data', 'error');
                this.halamanloading = false;
                return false;
            })
        },
        async filterdataReffPublik() {
            this.halamanloading = true;
            const mainAPIReFF = axios.create({
                baseURL: process.env.VUE_APP_URL_API,
                headers: {
                    Authorization: "Bearer GPMop8LQ06S0rZXcJyEH3wk8jVrINbHwn7tBq2",
                },
            });
            var random = Math.random();
            //alert(this.selectedkewenangan);
            await mainAPIReFF.get("parastapainnovation_p-GetDI?random=" + random + "&kewenangan=" + this.selectedkewenangan).then(
                Response => {
                    this.datapesan = Response.data.message;
                    if (this.datapesan == 'data diketemukan') {
                        this.datakodeDI = [];
                        this.kodeDI = [];
                        this.datakodeDI = Response.data.content.data;
                        this.datakodeDI.forEach((item) => {
                            this.kodeDI.push({
                                label: '[' + item.kode + '] ' + item.nama,
                                code: this.datakodeDI.indexOf(item) + 1,
                                tag: item.kode,
                            });
                        });
                        this.kodeDI.unshift({
                            label: 'Semua Data',
                            code: 0,
                            tag: '',
                        });
                    }
                    else {
                        this.datakodeDI = [];
                        this.kodeDI = [];
                        this.kodeDI.unshift({
                            label: 'Semua Data',
                            code: 0,
                            tag: '',
                        });
                    }
                    this.halamanloading = false;

                }
            ).catch(error => {
                swal.fire('Peringatan', 'Kesalahan fecthing data', 'error');
                this.halamanloading = false;
                return false;
            })
            await mainAPIReFF.get("parastapainnovation_p-GetBalai?random=" + random + "&kewenangan=" + this.selectedkewenangan).then(
                Response => {
                    this.datapesan = Response.data.message;
                    if (this.datapesan == 'data diketemukan') {
                        this.databalai = [];
                        this.balai = [];
                        this.databalai = Response.data.content.data;
                        this.databalai.forEach((item) => {
                            this.balai.push({
                                label: item.nama,
                                code: this.databalai.indexOf(item) + 1,
                                tag: item.id,
                            });
                        });
                        this.balai.unshift({
                            label: 'Semua Data',
                            code: 0,
                            tag: '',
                        });
                    }
                    else {
                        this.databalai = [];
                        this.balai = [];
                        this.balai.unshift({
                            label: 'Semua Data',
                            code: 0,
                            tag: '',
                        });
                    }
                    this.halamanloading = false;
                }
            ).catch(error => {
                swal.fire('Peringatan', 'Kesalahan fecthing data', 'error');
                this.halamanloading = false;
                return false;
            })

            this.pindahhalaman(1);
        },
        onkewenanganChange(a) {
            try {
                this.selectedkewenangan = this.kewenangan[a].tag;
            }
            catch (err) {
                return;
            }
            this.defaultSelectedkodeDI = {
                code: 0,
                label: 'Semua Data',
                tag: '',
            };
            this.selectedkodeDI = '';
            this.defaultSelectedbalai = {
                code: 0,
                label: 'Semua Data',
                tag: '',
            };
            this.selectedbalai = '';
            this.filterdataReffPublik();
        },
        onbalaiChange(a) {
            try {
                this.selectedbalai = this.balai[a].tag;
            }
            catch (err) {
                return;
            }
            this.filterdataReffPublik();
        },
        onkodeDIChange(a) {
            try {
                this.selectedkodeDI = this.kodeDI[a].tag;
            }
            catch (err) {
                return;
            }
            this.filterdataReffPublik();
        },
        onChangeHalaman(event) {
            this.pindahhalaman(event.target.value);
        },
        async pindahhalaman(halamannya) {
            this.halamanloading = true;
            const mainAPI = axios.create({
                baseURL: process.env.VUE_APP_URL_API,
                headers: {
                    Authorization: "Bearer GPMop8LQ06S0rZXcJyEH3wk8jVrINbHwn7tBq2",
                },
            });
            //alert(this.selectedkewenangan);
            var random = Math.random();
            await mainAPI.get("parastapainnovation_portal-GetDI?random=" + random + "&halaman=" + halamannya + "&kodeDI=" + this.selectedkodeDI + "&balai=" + this.selectedbalai + "&kewenangan=" + this.selectedkewenangan).then(
                Response => {

                    this.datapesan = Response.data.message;
                    if (this.datapesan == 'data diketemukan') {
                        this.datamaster = [];
                        this.datamaster = Response.data.content.data;
                        this.jumlahdaerahirigasi = Response.data.resume[0].JumlahDI;
                        this.namamaxdaerahirigasi = Response.data.resume[0].NamaMaxLuasDI;
                        this.luasmaxdaerahirigasi = Response.data.resume[0].MaxLuasDI;
                        this.namamindaerahirigasi = Response.data.resume[0].NamaMinLuasDI;
                        this.luasmindaerahirigasi = Response.data.resume[0].MinLuasDI;
                        this.totalluasdaerahirigasi = Response.data.resume[0].TotalLuasDI;
                        this.rataluasdaerahirigasi = Response.data.resume[0].AVGLuasDI;
                        this.berlembaga = Response.data.resume[0].IP3ABerlembaga;
                        this.takberlembaga = Response.data.resume[0].IP3ATakBerlembaga;
                        this.gberlembaga = Response.data.resume[0].GP3ABerlembaga;
                        this.gtakberlembaga = Response.data.resume[0].GP3ATakBerlembaga;
                        this.pberlembaga = Response.data.resume[0].P3ABerlembaga;
                        this.ptakberlembaga = Response.data.resume[0].P3ATakBerlembaga;
                        this.KewenanganPusat = Response.data.resume[0].KewenanganPusat;
                        this.KewenanganProvinsi = Response.data.resume[0].KewenanganProvinsi;
                        this.KewenanganKab = Response.data.resume[0].KewenanganKab;
                        this.TotalIP3A = Response.data.resume[0].JumlahTotalIP3A;
                        this.TotalIP3Aevkin = Response.data.resume[0].JumlahIP3Asudahevkin;
                        this.TotalGP3A = Response.data.resume[0].JumlahTotalGP3A;
                        this.TotalGP3Aevkin = Response.data.resume[0].JumlahGP3Asudahevkin;
                        this.TotalP3A = Response.data.resume[0].JumlahTotalP3A;
                        this.TotalP3Aevkin = Response.data.resume[0].JumlahP3Asudahevkin;
                        this.klasifikasi = Response.data.resume[0].DataTotalKlasifikasiEvkinP3A;
                        this.totalbangunan = this.datamaster.reduce((a, { JumlahBangunan: e }) => a + e, 0);
                        this.totalsaluran = this.datamaster.reduce((a, { JumlahSaluran: e }) => a + e, 0);
                        this.totalpetak = this.datamaster.reduce((a, { JumlahPetak: e }) => a + e, 0);
                        var temptabel = Response.data.resume[0].DataTotalKlasifikasiEvkinP3A;
                        //alert(temptabel.length);
                        this.series = [];
                        for (let i = 0; i < temptabel.length; i++) {
                            this.series.push({
                                name: temptabel[i].KodeKlasifikasiEvkin + ' (' + temptabel[i].KlasifikasiEvkin + ')',
                                data: [temptabel[i].JumlahIP3A, temptabel[i].JumlahGP3A, temptabel[i].JumlahP3A]
                            });
                        }
                        this.jumlahdata = Response.data.content.jumlahdata;
                        this.jumlahhalaman = Response.data.content.jumlahhalaman;
                        this.mulai = Response.data.content.mulai;
                        if (this.mulai == 0) {
                            this.halaman = 1;
                        }
                        else {
                            this.halaman = (this.mulai / 50) + 1;
                        }
                    }
                    this.halamanloading = false;
                }
            ).catch(error => {
                this.datapesan = [];
                this.datamaster = [];
                this.halamanloading = false;
            });
        },
        async ambildata() {
            this.halamanloading = true;
            const mainAPI = axios.create({
                baseURL: process.env.VUE_APP_URL_API,
                headers: {
                    Authorization: "Bearer GPMop8LQ06S0rZXcJyEH3wk8jVrINbHwn7tBq2",
                },
            });
            var random = Math.random();
            await mainAPI.get("parastapainnovation_portal-GetDI?random=" + random + "&kodeDI=" + this.selectedkodeDI + "&balai=" + this.selectedbalai + "&kewenangan=" + this.selectedkewenangan).then(
                Response => {

                    this.datapesan = Response.data.message;
                    if (this.datapesan == 'data diketemukan') {
                        this.datamaster = [];
                        this.datamaster = Response.data.content.data;
                        this.jumlahdaerahirigasi = Response.data.resume[0].JumlahDI;
                        this.namamaxdaerahirigasi = Response.data.resume[0].NamaMaxLuasDI;
                        this.luasmaxdaerahirigasi = Response.data.resume[0].MaxLuasDI;
                        this.namamindaerahirigasi = Response.data.resume[0].NamaMinLuasDI;
                        this.luasmindaerahirigasi = Response.data.resume[0].MinLuasDI;
                        this.totalluasdaerahirigasi = Response.data.resume[0].TotalLuasDI;
                        this.rataluasdaerahirigasi = Response.data.resume[0].AVGLuasDI;
                        this.berlembaga = Response.data.resume[0].IP3ABerlembaga;
                        this.takberlembaga = Response.data.resume[0].IP3ATakBerlembaga;
                        this.gberlembaga = Response.data.resume[0].GP3ABerlembaga;
                        this.gtakberlembaga = Response.data.resume[0].GP3ATakBerlembaga;
                        this.pberlembaga = Response.data.resume[0].P3ABerlembaga;
                        this.ptakberlembaga = Response.data.resume[0].P3ATakBerlembaga;
                        this.KewenanganPusat = Response.data.resume[0].KewenanganPusat;
                        this.KewenanganProvinsi = Response.data.resume[0].KewenanganProvinsi;
                        this.KewenanganKab = Response.data.resume[0].KewenanganKab;
                        this.TotalIP3A = Response.data.resume[0].JumlahTotalIP3A;
                        this.TotalIP3Aevkin = Response.data.resume[0].JumlahIP3Asudahevkin;
                        this.TotalGP3A = Response.data.resume[0].JumlahTotalGP3A;
                        this.TotalGP3Aevkin = Response.data.resume[0].JumlahGP3Asudahevkin;
                        this.TotalP3A = Response.data.resume[0].JumlahTotalP3A;
                        this.TotalP3Aevkin = Response.data.resume[0].JumlahP3Asudahevkin;
                        this.klasifikasi = Response.data.resume[0].DataTotalKlasifikasiEvkinP3A;
                        this.totalbangunan = this.datamaster.reduce((a, { JumlahBangunan: e }) => a + e, 0);
                        this.totalsaluran = this.datamaster.reduce((a, { JumlahSaluran: e }) => a + e, 0);
                        this.totalpetak = this.datamaster.reduce((a, { JumlahPetak: e }) => a + e, 0);
                        var temptabel = Response.data.resume[0].DataTotalKlasifikasiEvkinP3A;
                        //alert(temptabel.length);
                        this.series = [];
                        for (let i = 0; i < temptabel.length; i++) {
                            this.series.push({
                                name: temptabel[i].KodeKlasifikasiEvkin + ' (' + temptabel[i].KlasifikasiEvkin + ')',
                                data: [temptabel[i].JumlahIP3A, temptabel[i].JumlahGP3A, temptabel[i].JumlahP3A]
                            });
                        }

                        this.jumlahdata = Response.data.content.jumlahdata;
                        this.jumlahhalaman = Response.data.content.jumlahhalaman;
                        this.mulai = Response.data.content.mulai;
                        if (this.mulai == 0) {
                            this.halaman = 1;
                        }
                        else {
                            this.halaman = (this.mulai / 50) + 1;
                        }
                    }
                    this.halamanloading = false;
                }
            ).catch(error => {
                this.datapesan = [];
                this.datamaster = [];
                this.halamanloading = false;
            });
        }
    },
    mounted() {
        this.ambildataReffPublik();
        this.ambildata();
    },
}
</script>

<style></style>