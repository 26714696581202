<template>
    <Top />
    <!-- Spinner Start -->
    <div id="spinner" v-if="halamanloading"
        class="show bg-white position-fixed translate-middle w-100 vh-100 top-50 start-50 d-flex align-items-center justify-content-center">
        <div class="spinner-border text-danger" style="width: 3rem; height: 3rem;" role="status">
            <span class="sr-only">Loading...</span>
        </div>
        &nbsp;<img src="../../../../src/assets/img/disdag.png" height="40">
        &nbsp;<span class="text-danger">..Loading...</span>
    </div>
    <!-- Spinner End -->
    <!-- Header Start -->
    <div class="container-fluid bg-breadcrumb">
        <div class="container text-center py-5" style="max-width: 900px;">
            <h3 class="text-white display-3 mb-4 wow fadeInDown pmisputih" data-wow-delay="0.1s">
                Data Gabungan Perkumpulanan Petani Pemakai Air (GP3A)
            </h3>
            <ol class="breadcrumb justify-content-center mb-0 wow fadeInDown" data-wow-delay="0.3s">
                <li class="breadcrumb-item">
                    <a href="/">
                        Beranda
                    </a>
                </li>
                <li class="breadcrumb-item active text-warning">
                    GP3A
                </li>
            </ol>
        </div>
    </div>
    <!-- Header End -->
    <!-- Contact Start -->
    <div class="container-fluid blog py-2">
        &nbsp;
    </div>
    <div class="container-fluid bg-breadcrumbberanda px-5 wow fadeInDown">
        <div class="card collapsed-card">
            <div class="card-header" data-card-widget="collapse" style="cursor: pointer;">
                <h5 class="card-title">Filter Data</h5>

                <div class="card-tools">
                    <button type="button" class="btn btn-tool" data-card-widget="collapse">
                        <i class="fas fa-plus"></i>
                    </button>
                </div>
            </div>
            <!-- /.card-header -->
            <div class="card-body">
                <div class="row">
                    <div class="col-sm-4">
                        <label>Pilihan Kewenangan </label>
                        <v-select :options="kewenangan" :reduce="(label) => label.code" label="label"
                            v-model="defaultSelectedkewenangan" @update:modelValue="onkewenanganChange"
                            style="background-color: #ffffff;"></v-select>
                    </div>
                    <div class="col-sm-4">
                        <label>Pilihan Daerah Irigasi </label>
                        <v-select :options="kodeDI" :reduce="(label) => label.code" label="label"
                            v-model="defaultSelectedkodeDI" @update:modelValue="onkodeDIChange"
                            style="background-color: #ffffff;"></v-select>
                    </div>
                    <div class="col-sm-4">
                        <label>Pilihan Balai </label>
                        <v-select :options="balai" :reduce="(label) => label.code" label="label"
                            v-model="defaultSelectedbalai" @update:modelValue="onbalaiChange"
                            style="background-color: #ffffff;"></v-select>
                    </div>
                </div>
                <!-- /.row -->
            </div>
            <!-- ./card-body -->
            <div class="card-footer">

            </div>
            <!-- /.card-footer -->
        </div>
    </div>
    <div class="container-fluid bg-breadcrumbberanda px-5 wow fadeInUp">
        <div class="card">
            <div class="card-header" data-card-widget="collapse" style="cursor: pointer;">
                <h5 class="card-title">Infografis GP3A</h5>

                <div class="card-tools">
                    <button type="button" class="btn btn-tool" data-card-widget="collapse">
                        <i class="fas fa-minus"></i>
                    </button>
                </div>
            </div>
            <!-- /.card-header -->
            <div class="card-body">
                <div class="row px-2">
                    <div class="col-sm-3 col-6 bg-white">
                        <div class="description-block border-right">
                            <span class="description-percentage text-success">GP3A BERBADAN HUKUM</span>
                            <h5 class="description-header">{{ G_numFormat(gberlembaga) }}</h5>
                            <span class="description-text">Unit</span>
                        </div>
                        <!-- /.description-block -->
                    </div>
                    <!-- /.col -->
                    <div class="col-sm-3 col-6 bg-white">
                        <div class="description-block">
                            <span class="description-percentage text-danger">GP3A TIDAK BERBADAN HUKUM</span>
                            <h5 class="description-header">{{ G_numFormat(gtakberlembaga) }} </h5>
                            <span class="description-text">Unit</span>
                        </div>
                        <!-- /.description-block -->
                    </div>
                    <div class="col-sm-3 col-6 bg-white">
                        <div class="description-block border-right">
                            <span class="description-percentage text-success">P3A BERBADAN HUKUM</span>
                            <h5 class="description-header">{{ G_numFormat(pberlembaga) }}</h5>
                            <span class="description-text">Unit</span>
                        </div>
                        <!-- /.description-block -->
                    </div>
                    <!-- /.col -->
                    <div class="col-sm-3 col-6 bg-white">
                        <div class="description-block">
                            <span class="description-percentage text-danger">P3A TIDAK BERBADAN HUKUM</span>
                            <h5 class="description-header">{{ G_numFormat(ptakberlembaga) }} </h5>
                            <span class="description-text">Unit</span>
                        </div>
                        <!-- /.description-block -->
                    </div>
                </div>
                <div class="row px-2">
                    <div class="col-12 col-sm-12 col-md-12">&nbsp;</div>
                </div>
                <div class="row px-2">
                    <div class="col-12 col-sm-6 col-md-6">
                        <div class="info-box">
                            <span class="info-box-icon bg-cyan elevation-1"><i class="fas fa-user-shield"></i></span>
                            <div class="info-box-content">
                                <span class="info-box-text">Total GP3A</span>
                                <span class="info-box-number"> {{ G_numFormat(TotalGP3A) }}
                                    <small>Unit</small>
                                </span>
                            </div>
                            <!-- /.info-box-content -->
                        </div>
                        <!-- /.info-box -->
                    </div>
                    <div class="col-12 col-sm-6 col-md-6">
                        <div class="info-box">
                            <span class="info-box-icon bg-gray elevation-1"><i class="fas fa-user-tie"></i></span>
                            <div class="info-box-content">
                                <span class="info-box-text">Total P3A</span>
                                <span class="info-box-number"> {{ G_numFormat(TotalP3A) }}
                                    <small>Unit</small>
                                </span>
                            </div>
                            <!-- /.info-box-content -->
                        </div>
                        <!-- /.info-box -->
                    </div>
                    <div class="col-12 col-sm-12 col-md-12">
                        <apexchart height="350" :options="chartOptions" :series="series">
                        </apexchart>
                    </div>
                    <div class="col-12 col-sm-12 col-md-12">&nbsp;</div>
                </div>

            </div>
            <!-- ./card-body -->
            <div class="card-footer">

            </div>
            <!-- /.card-footer -->
        </div>
    </div>
    <div class="container-fluid bg-breadcrumbberanda px-5">
        <div class="card">
            <div class="card-header" data-card-widget="collapse" style="cursor: pointer;">
                <h5 class="card-title">Daftar GP3A</h5>

                <div class="card-tools">
                    <button type="button" class="btn btn-tool" data-card-widget="collapse">
                        <i class="fas fa-minus"></i>
                    </button>
                </div>
            </div>
            <!-- /.card-header -->
            <div class="card-body">
                <div class="row">
                    <div class="col-sm-12 float-left pb-2 px-3">
                        <div class="card card-warning collapsed-card">
                            <div class="card-header" data-card-widget="collapse" style="cursor: pointer;">
                                <h3 class="card-title">Show / Hide Kolom</h3>
                                <div class="card-tools">
                                    <button type="button" class="btn btn-tool" data-card-widget="collapse"><i
                                            class="fas fa-plus" style="color: #ffffff;"></i>
                                    </button>
                                </div>
                            </div>
                            <div class="card-body">
                                <div class="card">
                                    <div class="card-body">
                                        <div class="row">
                                            <div class="col-lg-2 col-12">
                                                <div class="custom-control custom-checkbox">
                                                    <input class="custom-control-input custom-control-input-warning"
                                                        type="checkbox" id="customCheckboxKode" value="optionKode"
                                                        @click="showkode()">
                                                    <label for="customCheckboxKode" class="custom-control-label">
                                                        Kode D.I.</label>
                                                </div>
                                            </div>
                                            <div class="col-lg-2 col-12">
                                                <div class="custom-control custom-checkbox">
                                                    <input class="custom-control-input custom-control-input-warning"
                                                        type="checkbox" id="customCheckboxNamaDI" value="optionNamaDI"
                                                        @click="showNamaDI()">
                                                    <label for="customCheckboxNamaDI" class="custom-control-label">
                                                        Nama D.I.</label>
                                                </div>
                                            </div>
                                            <div class="col-lg-2 col-12">
                                                <div class="custom-control custom-checkbox">
                                                    <input class="custom-control-input custom-control-input-warning"
                                                        type="checkbox" id="customCheckboxLuas" value="optionLuas"
                                                        @click="showluas()">
                                                    <label for="customCheckboxLuas" class="custom-control-label">
                                                        Luas</label>
                                                </div>
                                            </div>
                                            <div class="col-lg-2 col-12">
                                                <div class="custom-control custom-checkbox">
                                                    <input class="custom-control-input custom-control-input-warning"
                                                        type="checkbox" id="customCheckboxBalai" value="optionBalai"
                                                        @click="showbalai()">
                                                    <label for="customCheckboxBalai" class="custom-control-label">
                                                        Balai</label>
                                                </div>
                                            </div>
                                            <div class="col-lg-2 col-12">
                                                <div class="custom-control custom-checkbox">
                                                    <input class="custom-control-input custom-control-input-warning"
                                                        type="checkbox" id="customCheckboxKewenangan"
                                                        value="optionKewenangan" @click="showkewenangan()">
                                                    <label for="customCheckboxKewenangan" class="custom-control-label">
                                                        Kewenangan</label>
                                                </div>
                                            </div>
                                            <div class="col-lg-2 col-12">
                                                <div class="custom-control custom-checkbox">
                                                    <input class="custom-control-input custom-control-input-warning"
                                                        type="checkbox" id="customCheckboxIP3A" value="optionIP3A"
                                                        @click="showIP3A()">
                                                    <label for="customCheckboxIP3A" class="custom-control-label">
                                                        IP3A</label>
                                                </div>
                                            </div>
                                            <div class="col-lg-2 col-12">
                                                <div class="custom-control custom-checkbox">
                                                    <input class="custom-control-input custom-control-input-warning"
                                                        type="checkbox" id="customCheckboxAkta" value="optionAkta"
                                                        @click="showakta()">
                                                    <label for="customCheckboxAkta" class="custom-control-label">
                                                        Akta</label>
                                                </div>
                                            </div>
                                            <div class="col-lg-2 col-12">
                                                <div class="custom-control custom-checkbox">
                                                    <input class="custom-control-input custom-control-input-warning"
                                                        type="checkbox" id="customCheckboxSK" value="optionSK"
                                                        @click="showsk()">
                                                    <label for="customCheckboxSK" class="custom-control-label">
                                                        SK</label>
                                                </div>
                                            </div>
                                            <div class="col-lg-2 col-12">
                                                <div class="custom-control custom-checkbox">
                                                    <input class="custom-control-input custom-control-input-warning"
                                                        type="checkbox" id="customCheckboxP3A" value="optionP3A" checked
                                                        @click="showP3A()">
                                                    <label for="customCheckboxP3A" class="custom-control-label">
                                                        P3A</label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-12 float-left pb-2 px-3 wow fadeInDown">
                        Jumlah Data: {{ G_numFormat(jumlahdata) }}
                        <span style="float: right;">
                            Halaman ke: <select v-model="halaman" @change="onChangeHalaman($event)">
                                <option v-for="index in jumlahhalaman" :key="index">
                                    {{ index }}</option>
                            </select> dari {{ jumlahhalaman }}
                        </span>
                    </div>
                    <div class="col-sm-12 float-left pb-2 wow fadeInDown">
                        <input type="text" v-model="carikata" class="form-control form-control-sm"
                            placeholder="Masukkan Kata Kunci Pencarian Halaman Terpilih">
                    </div>
                    <div class="table-responsive table-wrapper">
                        <table class="table m-0" id="tabeldata">
                            <thead>
                                <tr>
                                    <th style="width: 50px;" rowspan="3">No</th>
                                    <th style="width: 50px;" rowspan="3" v-if="showColumnKode">Kode</th>
                                    <th style="width: 50px;" rowspan="3" v-if="showColumnNamaDI">Daerah Irigasi</th>
                                    <th style="width: 60px;" rowspan="3" v-if="showColumnLuas">Luas<br>(Ha)</th>
                                    <th style="width: 100px;" rowspan="3" v-if="showColumnBalai">Nama<br>Balai</th>
                                    <th style="width: 100px;" rowspan="3" v-if="showColumnKewenangan">Kewenangan</th>
                                    <th style="width: 100px;" rowspan="3" v-if="showColumnIP3A">Nama<br>IP3A</th>
                                    <th style="width: 100px;" rowspan="3">Nama<br>GP3A</th>
                                    <th style="width: 50px;" rowspan="3">Tahun<br>Pembentukan<br>GP3A</th>
                                    <th style="width: 100px;" rowspan="3" v-if="showColumnAkta">Akta<br>GP3A</th>
                                    <th style="width: 100px;" rowspan="3" v-if="showColumnSK">SK<br>GP3A</th>
                                    <th style="width: 50px;" rowspan="3">Nilai<br>Evaluasi<br>Kinerja</th>
                                    <th style="width: 50px;" rowspan="3">Kategori<br>Evaluasi<br>Kinerja</th>
                                    <th style="width: 100px;" :colspan="colspan2"
                                        v-if="showColumnP3A">Kelembagaan P3A<br>(unit)</th>
                                </tr>
                                <tr>
                                    <th style="width: 50px;" rowspan="2" v-if="showColumnP3A">Total</th>
                                    <th style="width: 50px;" colspan="5" v-if="showColumnP3A">Kategori<br>Evaluasi<br>Kinerja</th>
                                    <th style="width: 50px;" rowspan="2" v-if="showColumnP3A">Belum<br>Evaluasi<br>Kinerja</th>
                                </tr>
                                <tr>
                                    <th style="width: 50px;" v-if="showColumnP3A">Jumlah</th>
                                    <th style="width: 50px;" v-if="showColumnP3A">BB</th>
                                    <th style="width: 50px;" v-if="showColumnP3A">SB</th>
                                    <th style="width: 50px;" v-if="showColumnP3A">B</th>
                                    <th style="width: 50px;" v-if="showColumnP3A">M</th>
                                </tr>
                                <tr>
                                    <td class="levelsubkegiatan" :colspan="colspan1"> TOTAL</td>
                                    <td class="levelsubkegiatan text-center" v-if="showColumnP3A">
                                        {{ G_numFormat(TotalP3A) }}
                                    </td>
                                    <td class="levelsubkegiatan text-center" v-if="showColumnP3A">
                                        {{ G_numFormat(TotalP3Aevkin) }}
                                    </td>
                                    <td v-if="showColumnP3A" class="levelsubkegiatan text-center"
                                        v-for="(datalist, urutlist) in klasifikasi" :key="urutlist">
                                        {{ G_numFormat(datalist.JumlahP3A) }}
                                    </td>
                                    <td v-if="datapesan == 'data kosong'" class="levelsubkegiatan text-center" v-for="n in 5">
                                        0
                                    </td>
                                </tr>
                            </thead>
                            <template v-for="(datalist, urutlist) in filteredKataData" :key="urutlist">
                                <tbody>
                                    <tr>
                                        <td class="text-center">{{ urutlist + 1 }}</td>
                                        <td v-if="showColumnKode">
                                            {{ datalist.KodeDI }}
                                        </td>
                                        <td v-if="showColumnNamaDI">
                                            {{ datalist.NamaDI }}
                                        </td>
                                        <td class="text-right" v-if="showColumnLuas">
                                            {{ G_numFormatKoma(datalist.LuasHa) }}
                                        </td>
                                        <td v-if="showColumnBalai">
                                            {{ datalist.NmBalai }}
                                        </td>
                                        <td v-if="showColumnKewenangan">
                                            {{ datalist.NamaKewenangan }}
                                        </td>
                                        <td v-if="showColumnIP3A">
                                            {{ datalist.NamaIP3A }}
                                        </td>
                                        <td>
                                            {{ datalist.NamaGP3A }}
                                        </td>
                                        <td class="text-center">
                                            <span v-if="datalist.TahunGP3A != 0">{{ datalist.TahunGP3A }}</span>
                                            <span v-else>Tidak terdata</span>
                                        </td>
                                        <td v-if="showColumnAkta">
                                            {{ datalist.NoAktaKunHam }}
                                        </td>
                                        <td v-if="showColumnSK">
                                            {{ datalist.NoSK }}
                                        </td>
                                        <td class="text-center">
                                            {{ G_numFormatKoma(datalist.NilaiEvkin) }}
                                        </td>
                                        <td class="text-center">
                                            {{ datalist.KodeKlasifikasiEvkin }}
                                        </td>
                                        <td class="text-center" v-if="showColumnP3A">
                                            {{ G_numFormat(datalist.JumlahP3A) }}
                                        </td>
                                        <td class="text-center" v-if="showColumnP3A">
                                            {{ G_numFormat(datalist.JumlahP3Asudahevkin) }}
                                        </td>
                                        <td v-if="showColumnP3A" class="text-center"
                                            v-for="(DataKlasifikasiEvkinP3A, urutDataKlasifikasiEvkinP3A) in datalist.DataKlasifikasiEvkinP3A"
                                            :key="urutDataKlasifikasiEvkinP3A">
                                            {{ G_numFormat(DataKlasifikasiEvkinP3A.JumlahP3A) }}
                                        </td>
                                    </tr>
                                </tbody>
                            </template>
                        </table>
                    </div>
                </div>
                <!-- /.row -->
            </div>
            <!-- ./card-body -->
            <div class="card-footer">

            </div>
            <!-- /.card-footer -->
        </div>
    </div>
    <div class="col-sm-12">&nbsp;</div>
    <!-- Contact End -->
    <a href="#" class="btn btn-dangerbulet btn-lg-square back-to-top text-white"><i class="fa fa-arrow-up"></i></a>
    <Footer />
</template>

<script>
import axios from 'axios';
import $ from 'jquery';
import CryptoJS from 'crypto-js';
import { useRoute } from 'vue-router';
import { watch } from 'vue';
import swal from 'sweetalert2';
import vSelect from 'vue-select';
import VueApexCharts from "vue3-apexcharts";
window.Apex.chart = { fontFamily: "Poppins-SemiBold, Arial, sans-serif" };
import Top from "./Template/Top.vue";
import Footer from "./Template/Footer.vue";

export default {
    setup() {
        const route = useRoute()
        watch(() => route.name, () => {
        });

        return { route };
    },
    components: {
        Top, Footer, swal, vSelect, apexchart: VueApexCharts,
    },
    data() {
        return {
            halamanloading: true,
            secretencData: decodeURIComponent(CryptoJS.AES.decrypt(this.route.params.id, 'oriproMop8LQ06S0rZXcJyEH3wk8jVrINbHwn7tBq2').toString(CryptoJS.enc.Utf8)),
            tahun: new Date().getFullYear(),
            bahasa: localStorage.getItem("bahasasistem"),
            datapesan: '',
            datamaster: [],
            klasifikasi: [],
            carikata: '',
            gberlembaga: 0,
            gtakberlembaga: 0,
            pberlembaga: 0,
            ptakberlembaga: 0,
            TotalGP3A: 0,
            TotalGP3Aevkin: 0,
            TotalP3A: 0,
            TotalP3Aevkin: 0,
            jumlahdata: 0,
            jumlahhalaman: 0,
            mulai: 0,
            halaman: 1,
            kewenangan: [],
            datakewenangan: [],
            defaultSelectedkewenangan: {
                code: 0,
                label: 'Semua Data',
                tag: '',
            },
            selectedkewenangan: '',
            kodeDI: [],
            datakodeDI: [],
            defaultSelectedkodeDI: {
                code: 0,
                label: 'Semua Data',
                tag: '',
            },
            selectedkodeDI: '',
            balai: [],
            databalai: [],
            defaultSelectedbalai: {
                code: 0,
                label: 'Semua Data',
                tag: '',
            },
            selectedbalai: '',
            totalsaluran: 0,
            totalbangunan: 0,
            totalpetak: 0,
            showColumnKode: false,
            showColumnLuas: false,
            showColumnBalai: false,
            showColumnKewenangan: false,
            showColumnNamaDI: false,
            showColumnAkta: false,
            showColumnSK: false,
            showColumnIP3A: false,
            colspan1: 5,
            colspan2: 7,

            showColumnP3A: true,
            series: [],
            chartOptions: {
                colors: [
                    '#f9a867',
                    '#6a6ba5',
                    '#6c98b7',
                    '#2a6b9b',
                    '#d5e2eb',
                ],
                chart: {
                    type: 'bar',
                    height: 350,
                    stacked: true,
                    toolbar: {
                        show: true
                    },
                    zoom: {
                        enabled: true
                    }
                },
                responsive: [{
                    breakpoint: 480,
                    options: {
                        legend: {
                            position: 'bottom',
                            offsetX: -10,
                            offsetY: 0
                        }
                    }
                }],
                plotOptions: {
                    bar: {
                        horizontal: false,
                        borderRadius: 10,
                        borderRadiusApplication: 'end', // 'around', 'end'
                        borderRadiusWhenStacked: 'last', // 'all', 'last'
                        dataLabels: {
                            total: {
                                enabled: true,
                                style: {
                                    fontSize: '13px',
                                    fontWeight: 900
                                }
                            }
                        }
                    },
                },
                xaxis: {
                    type: 'text',
                    categories: ['GP3A', 'P3A'],
                },
                legend: {
                    position: 'right',
                    offsetY: 40
                },
                fill: {
                    opacity: 1
                }
            },
        };
    },
    computed: {
        filteredKataData() {
            const filteredWorkers = this.carikata === ""
                ? this.datamaster
                : this.datamaster.filter(wo => Object.values(wo).join("").toLowerCase().indexOf(this.carikata.toLowerCase()) !== -1);
            return filteredWorkers;
        },
        JumlahfilteredKataData() {
            var jumlah = 0;
            try {
                if (this.filteredKataData === undefined) {
                    jumlah = 0;
                } else {
                    jumlah = this.filteredKataData.length;
                }
            }
            catch {
                jumlah = 0;
            }
            return jumlah;
        },
    },
    methods: {
        showkode() {
            this.showColumnKode = !this.showColumnKode;
            if (this.showColumnKode == true) {
                this.colspan1 = this.colspan1 + 1;
            }
            else {
                this.colspan1 = this.colspan1 - 1;
            }
        },
        showluas() {
            this.showColumnLuas = !this.showColumnLuas;
            if (this.showColumnLuas == true) {
                this.colspan1 = this.colspan1 + 1;
            }
            else {
                this.colspan1 = this.colspan1 - 1;
            }
        },
        showakta() {
            this.showColumnAkta = !this.showColumnAkta;
            if (this.showColumnAkta == true) {
                this.colspan1 = this.colspan1 + 1;
            }
            else {
                this.colspan1 = this.colspan1 - 1;
            }
        },
        showsk() {
            this.showColumnSK = !this.showColumnSK;
            if (this.showColumnSK == true) {
                this.colspan1 = this.colspan1 + 1;
            }
            else {
                this.colspan1 = this.colspan1 - 1;
            }
        },
        showbalai() {
            this.showColumnBalai = !this.showColumnBalai;
            if (this.showColumnBalai == true) {
                this.colspan1 = this.colspan1 + 1;
            }
            else {
                this.colspan1 = this.colspan1 - 1;
            }
        },
        showkewenangan() {
            this.showColumnKewenangan = !this.showColumnKewenangan;
            if (this.showColumnKewenangan == true) {
                this.colspan1 = this.colspan1 + 1;
            }
            else {
                this.colspan1 = this.colspan1 - 1;
            }
        },
        showNamaDI() {
            this.showColumnNamaDI = !this.showColumnNamaDI;
            if (this.showColumnNamaDI == true) {
                this.colspan1 = this.colspan1 + 1;
            }
            else {
                this.colspan1 = this.colspan1 - 1;
            }
        },
        showIP3A() {
            this.showColumnIP3A = !this.showColumnIP3A;
            if (this.showColumnIP3A == true) {
                this.colspan1 = this.colspan1 + 1;
            }
            else {
                this.colspan1 = this.colspan1 - 1;
            }
        },
        showP3A() {
            this.showColumnP3A = !this.showColumnP3A;
            if (this.showColumnP3A == true) {
                this.colspan2 = this.colspan2 + 7;
            }
            else {
                this.colspan2 = this.colspan2 - 7;
            }
        },
        async ambildataReffPublik() {
            this.halamanloading = true;
            const mainAPIReFF = axios.create({
                baseURL: process.env.VUE_APP_URL_API,
                headers: {
                    Authorization: "Bearer GPMop8LQ06S0rZXcJyEH3wk8jVrINbHwn7tBq2",
                },
            });
            var random = Math.random();
            await mainAPIReFF.get("parastapainnovation_p-GetKewenangan?random=" + random).then(
                Response => {
                    this.datapesan = Response.data.message;
                    if (this.datapesan == 'data diketemukan') {
                        this.datakewenangan = [];
                        this.kewenangan = [];
                        this.datakewenangan = Response.data.content.data;
                        this.datakewenangan.forEach((item) => {
                            this.kewenangan.push({
                                label: item.nama,
                                code: this.datakewenangan.indexOf(item) + 1,
                                tag: item.id,
                            });
                        });
                        this.kewenangan.unshift({
                            label: 'Semua Data',
                            code: 0,
                            tag: '',
                        });
                    }
                    else {
                        this.datakewenangan = [];
                        this.kewenangan = [];
                        this.kewenangan.unshift({
                            label: 'Semua Data',
                            code: 0,
                            tag: '',
                        });
                    }
                    this.halamanloading = false;
                }
            ).catch(error => {
                swal.fire('Peringatan', 'Kesalahan fecthing data', 'error');
                this.halamanloading = false;
                return false;
            })
            await mainAPIReFF.get("parastapainnovation_p-GetDI?random=" + random + "&kewenangan=" + this.selectedkewenangan).then(
                Response => {
                    this.datapesan = Response.data.message;
                    if (this.datapesan == 'data diketemukan') {
                        this.datakodeDI = [];
                        this.kodeDI = [];
                        this.datakodeDI = Response.data.content.data;
                        this.datakodeDI.forEach((item) => {
                            this.kodeDI.push({
                                label: '[' + item.kode + '] ' + item.nama,
                                code: this.datakodeDI.indexOf(item) + 1,
                                tag: item.kode,
                            });
                        });
                        this.kodeDI.unshift({
                            label: 'Semua Data',
                            code: 0,
                            tag: '',
                        });
                    }
                    else {
                        this.datakodeDI = [];
                        this.kodeDI = [];
                        this.kodeDI.unshift({
                            label: 'Semua Data',
                            code: 0,
                            tag: '',
                        });
                    }
                    this.halamanloading = false;
                }
            ).catch(error => {
                swal.fire('Peringatan', 'Kesalahan fecthing data', 'error');
                this.halamanloading = false;
                return false;
            })
            await mainAPIReFF.get("parastapainnovation_p-GetBalai?random=" + random + "&kewenangan=" + this.selectedkewenangan).then(
                Response => {
                    this.datapesan = Response.data.message;
                    if (this.datapesan == 'data diketemukan') {
                        this.databalai = [];
                        this.balai = [];
                        this.databalai = Response.data.content.data;
                        this.databalai.forEach((item) => {
                            this.balai.push({
                                label: item.nama,
                                code: this.databalai.indexOf(item) + 1,
                                tag: item.id,
                            });
                        });
                        this.balai.unshift({
                            label: 'Semua Data',
                            code: 0,
                            tag: '',
                        });
                    }
                    else {
                        this.databalai = [];
                        this.balai = [];
                        this.balai.unshift({
                            label: 'Semua Data',
                            code: 0,
                            tag: '',
                        });
                    }
                    this.halamanloading = false;
                }
            ).catch(error => {
                swal.fire('Peringatan', 'Kesalahan fecthing data', 'error');
                this.halamanloading = false;
                return false;
            })
        },
        async filterdataReffPublik() {
            this.halamanloading = true;
            const mainAPIReFF = axios.create({
                baseURL: process.env.VUE_APP_URL_API,
                headers: {
                    Authorization: "Bearer GPMop8LQ06S0rZXcJyEH3wk8jVrINbHwn7tBq2",
                },
            });
            var random = Math.random();
            //alert(this.selectedkewenangan);
            await mainAPIReFF.get("parastapainnovation_p-GetDI?random=" + random + "&kewenangan=" + this.selectedkewenangan).then(
                Response => {
                    this.datapesan = Response.data.message;
                    if (this.datapesan == 'data diketemukan') {
                        this.datakodeDI = [];
                        this.kodeDI = [];
                        this.datakodeDI = Response.data.content.data;
                        this.datakodeDI.forEach((item) => {
                            this.kodeDI.push({
                                label: '[' + item.kode + '] ' + item.nama,
                                code: this.datakodeDI.indexOf(item) + 1,
                                tag: item.kode,
                            });
                        });
                        this.kodeDI.unshift({
                            label: 'Semua Data',
                            code: 0,
                            tag: '',
                        });
                    }
                    else {
                        this.datakodeDI = [];
                        this.kodeDI = [];
                        this.kodeDI.unshift({
                            label: 'Semua Data',
                            code: 0,
                            tag: '',
                        });
                    }
                    this.halamanloading = false;

                }
            ).catch(error => {
                swal.fire('Peringatan', 'Kesalahan fecthing data', 'error');
                this.halamanloading = false;
                return false;
            })
            await mainAPIReFF.get("parastapainnovation_p-GetBalai?random=" + random + "&kewenangan=" + this.selectedkewenangan).then(
                Response => {
                    this.datapesan = Response.data.message;
                    if (this.datapesan == 'data diketemukan') {
                        this.databalai = [];
                        this.balai = [];
                        this.databalai = Response.data.content.data;
                        this.databalai.forEach((item) => {
                            this.balai.push({
                                label: item.nama,
                                code: this.databalai.indexOf(item) + 1,
                                tag: item.id,
                            });
                        });
                        this.balai.unshift({
                            label: 'Semua Data',
                            code: 0,
                            tag: '',
                        });
                    }
                    else {
                        this.databalai = [];
                        this.balai = [];
                        this.balai.unshift({
                            label: 'Semua Data',
                            code: 0,
                            tag: '',
                        });
                    }
                    this.halamanloading = false;
                }
            ).catch(error => {
                swal.fire('Peringatan', 'Kesalahan fecthing data', 'error');
                this.halamanloading = false;
                return false;
            })

            this.pindahhalaman(1);
        },
        onkewenanganChange(a) {
            try {
                this.selectedkewenangan = this.kewenangan[a].tag;
            }
            catch (err) {
                return;
            }
            this.defaultSelectedkodeDI = {
                code: 0,
                label: 'Semua Data',
                tag: '',
            };
            this.selectedkodeDI = '';
            this.defaultSelectedbalai = {
                code: 0,
                label: 'Semua Data',
                tag: '',
            };
            this.selectedbalai = '';
            this.filterdataReffPublik();
        },
        onbalaiChange(a) {
            try {
                this.selectedbalai = this.balai[a].tag;
            }
            catch (err) {
                return;
            }
            this.filterdataReffPublik();
        },
        onkodeDIChange(a) {
            try {
                this.selectedkodeDI = this.kodeDI[a].tag;
            }
            catch (err) {
                return;
            }
            this.filterdataReffPublik();
        },
        onChangeHalaman(event) {
            this.pindahhalaman(event.target.value);
        },
        async pindahhalaman(halamannya) {
            this.halamanloading = true;
            const mainAPI = axios.create({
                baseURL: process.env.VUE_APP_URL_API,
                headers: {
                    Authorization: "Bearer GPMop8LQ06S0rZXcJyEH3wk8jVrINbHwn7tBq2",
                },
            });
            //alert(this.selectedkewenangan);
            var random = Math.random();
            await mainAPI.get("parastapainnovation_portalgp3a-GetGP3A?random=" + random + "&halaman=" + halamannya + "&kodeDI=" + this.selectedkodeDI + "&balai=" + this.selectedbalai + "&kewenangan=" + this.selectedkewenangan).then(
                Response => {

                    this.datapesan = Response.data.message;
                    if (this.datapesan == 'data diketemukan') {
                        this.datamaster = [];
                        this.datamaster = Response.data.content.data;
                        this.gberlembaga = Response.data.resume[0].GP3ABerlembaga;
                        this.gtakberlembaga = Response.data.resume[0].GP3ATakBerlembaga;
                        this.pberlembaga = Response.data.resume[0].P3ABerlembaga;
                        this.ptakberlembaga = Response.data.resume[0].P3ATakBerlembaga;
                        this.TotalGP3A = Response.data.resume[0].JumlahTotalGP3A;
                        this.TotalGP3Aevkin = Response.data.resume[0].JumlahGP3Asudahevkin;
                        this.TotalP3A = Response.data.resume[0].JumlahTotalP3A;
                        this.TotalP3Aevkin = Response.data.resume[0].JumlahP3Asudahevkin;
                        this.klasifikasi = Response.data.resume[0].DataTotalKlasifikasiEvkinP3A;
                        var temptabel = Response.data.resume[0].DataTotalKlasifikasiEvkinP3A;
                        //alert(temptabel.length);
                        this.series = [];
                        for (let i = 0; i < temptabel.length; i++) {
                            this.series.push({
                                name: temptabel[i].KodeKlasifikasiEvkin + ' (' + temptabel[i].KlasifikasiEvkin + ')',
                                data: [temptabel[i].JumlahGP3A, temptabel[i].JumlahP3A]
                            });
                        }
                        this.jumlahdata = Response.data.content.jumlahdata;
                        this.jumlahhalaman = Response.data.content.jumlahhalaman;
                        this.mulai = Response.data.content.mulai;
                        if (this.mulai == 0) {
                            this.halaman = 1;
                        }
                        else {
                            this.halaman = (this.mulai / 50) + 1;
                        }
                    }
                    else {
                        
                        this.datamaster = [];
                        this.series = [];
                        this.gberlembaga = 0;
                        this.gtakberlembaga = 0;
                        this.pberlembaga = 0;
                        this.ptakberlembaga = 0;
                        this.TotalGP3A = 0;
                        this.TotalGP3Aevkin = 0;
                        this.TotalP3A = 0;
                        this.TotalP3Aevkin = 0;
                        this.klasifikasi = 0;
                        this.jumlahdata = 0;
                        this.jumlahhalaman = 0;
                        this.mulai = 0;
                        this.halaman = 1;
                    }
                    this.halamanloading = false;
                }
            ).catch(error => {
                this.datapesan = [];
                this.datamaster = [];
                this.halamanloading = false;
            });
        },
        async ambildata() {
            this.halamanloading = true;
            const mainAPI = axios.create({
                baseURL: process.env.VUE_APP_URL_API,
                headers: {
                    Authorization: "Bearer GPMop8LQ06S0rZXcJyEH3wk8jVrINbHwn7tBq2",
                },
            });
            var random = Math.random();
            await mainAPI.get("parastapainnovation_portalgp3a-GetGP3A?random=" + random + "&kodeDI=" + this.selectedkodeDI + "&balai=" + this.selectedbalai + "&kewenangan=" + this.selectedkewenangan).then(
                Response => {

                    this.datapesan = Response.data.message;
                    if (this.datapesan == 'data diketemukan') {
                        this.datamaster = [];
                        this.datamaster = Response.data.content.data;
                        this.gberlembaga = Response.data.resume[0].GP3ABerlembaga;
                        this.gtakberlembaga = Response.data.resume[0].GP3ATakBerlembaga;
                        this.pberlembaga = Response.data.resume[0].P3ABerlembaga;
                        this.ptakberlembaga = Response.data.resume[0].P3ATakBerlembaga;
                        this.TotalGP3A = Response.data.resume[0].JumlahTotalGP3A;
                        this.TotalGP3Aevkin = Response.data.resume[0].JumlahGP3Asudahevkin;
                        this.TotalP3A = Response.data.resume[0].JumlahTotalP3A;
                        this.TotalP3Aevkin = Response.data.resume[0].JumlahP3Asudahevkin;
                        this.klasifikasi = Response.data.resume[0].DataTotalKlasifikasiEvkinP3A;
                        var temptabel = Response.data.resume[0].DataTotalKlasifikasiEvkinP3A;
                        //alert(JSON.stringify(temptabel));
                        //alert(temptabel.length);
                        this.series = [];
                        for (let i = 0; i < temptabel.length; i++) {
                            this.series.push({
                                name: temptabel[i].KodeKlasifikasiEvkin + ' (' + temptabel[i].KlasifikasiEvkin + ')',
                                data: [temptabel[i].JumlahGP3A, temptabel[i].JumlahP3A]
                            });
                        }

                        this.jumlahdata = Response.data.content.jumlahdata;
                        this.jumlahhalaman = Response.data.content.jumlahhalaman;
                        this.mulai = Response.data.content.mulai;
                        if (this.mulai == 0) {
                            this.halaman = 1;
                        }
                        else {
                            this.halaman = (this.mulai / 50) + 1;
                        }
                    }
                    else {
                        
                        this.datamaster = [];
                        this.series = [];
                        this.gberlembaga = 0;
                        this.gtakberlembaga = 0;
                        this.pberlembaga = 0;
                        this.ptakberlembaga = 0;
                        this.TotalGP3A = 0;
                        this.TotalGP3Aevkin = 0;
                        this.TotalP3A = 0;
                        this.TotalP3Aevkin = 0;
                        this.klasifikasi = 0;
                        this.jumlahdata = 0;
                        this.jumlahhalaman = 0;
                        this.mulai = 0;
                        this.halaman = 1;
                    }
                    this.halamanloading = false;
                }
            ).catch(error => {
                this.datapesan = [];
                this.datamaster = [];
                this.halamanloading = false;
            });
        }
    },
    mounted() {
        this.ambildataReffPublik();
        this.ambildata();
    },
}
</script>

<style></style>