<template>
    <Top />
    <!-- Spinner Start -->
    <div id="spinner" v-if="halamanloading"
        class="show bg-white position-fixed translate-middle w-100 vh-100 top-50 start-50 d-flex align-items-center justify-content-center">
        <div class="spinner-border text-danger" style="width: 3rem; height: 3rem;" role="status">
            <span class="sr-only">Loading...</span>
        </div>
        &nbsp;<img src="../../../../src/assets/img/disdag.png" height="40">
        &nbsp;<span class="text-danger">..Loading...</span>
    </div>
    <!-- Spinner End -->
    <!-- Header Start -->
    <div class="container-fluid bg-breadcrumb">
        <div class="container text-center py-5" style="max-width: 900px;">
            <h3 class="text-white display-3 mb-4 wow fadeInDown pmisputih" data-wow-delay="0.1s">
                Ringkasan Eksekutif
            </h3>
            <ol class="breadcrumb justify-content-center mb-0 wow fadeInDown" data-wow-delay="0.3s">
                <li class="breadcrumb-item">
                    <a href="/">
                        <span v-if="(bahasa === null) || (bahasa == 'ina')">Beranda</span>
                        <span v-else>Home</span>
                    </a>
                </li>
                <li class="breadcrumb-item active text-warning">
                    Eksum
                </li>
            </ol>
        </div>
    </div>
    <!-- Header End -->
    <!-- Contact Start -->
    <div class="container-fluid blog py-2">&nbsp;</div>
    <div class="container-fluid bg-breadcrumbberanda wow fadeInDown">
        <div class="section-title mb-2 wow fadeInDown" data-wow-delay="0.1s">
            <div class="col-lg-12 col-xl-12 wow fadeInRight" data-wow-delay="0.3s">
                <div class="col-lg-12 col-xl-12 wow fadeInRight" data-wow-delay="0.3s">
                    &nbsp;
                </div>
                <div class="col-lg-12 col-xl-12 wow fadeInRight" data-wow-delay="0.3s">
                    &nbsp;
                </div>
                <div class="col-lg-12 col-xl-12 wow fadeInRight" data-wow-delay="0.3s">
                    <img src="../../../../src/assets/img/maintenance.png">
                </div>
                <div class="col-lg-12 col-xl-12 wow fadeInRight" data-wow-delay="0.3s">
                    &nbsp;
                </div>
                <div class="col-lg-12 col-xl-12 wow fadeInRight" data-wow-delay="0.3s">
                    &nbsp;
                </div>
            </div>
        </div>
    </div>
    <!-- Contact End -->
    <a href="#" class="btn btn-dangerbulet btn-lg-square back-to-top text-white"><i class="fa fa-arrow-up"></i></a>
    <Footer />
</template>

<script>
import axios from 'axios';
import $ from 'jquery';
import swal from 'sweetalert2';

import Top from "./Template/Top.vue";
import Footer from "./Template/Footer.vue";

export default {
    components: {
        Top, Footer, swal
    },
    data() {
        return {
            halamanloading: true,
            tahun: new Date().getFullYear(),
            bahasa: localStorage.getItem("bahasasistem"),
            sourcenya: '',
        };
    },
    methods: {
    },
    mounted() {
        this.halamanloading= false;
    },
}
</script>

<style></style>