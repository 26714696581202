<template>
  <div class="content" v-if="route.name == 'Beranda'">
    <Beranda/>
  </div>
  <div class="content" v-if="route.name == 'Investasi'">
    <Investasi/>
  </div>
  <div class="content" v-if="route.name == 'InvestasiData'">
    <InvestasiData/>
  </div>
  <div class="content" v-if="route.name == 'InvestasiDataKBLI'">
    <InvestasiDataKBLI/>
  </div>
  <div class="content" v-if="route.name == 'InvestasiDataResiko'">
    <InvestasiDataResiko/>
  </div>
  <div class="content" v-if="route.name == 'InvestasiDataStatus'">
    <InvestasiDataStatus/>
  </div>
  <div class="content" v-if="route.name == 'InvestasiDataSektor'">
    <InvestasiDataSektor/>
  </div>
  <div class="content" v-if="route.name == 'InvestasiDataPerusahaan'">
    <InvestasiDataPerusahaan/>
  </div>
  <div class="content" v-if="route.name == 'InvestasiDetail'">
    <InvestasiDetail/>
  </div>
  <div class="content" v-if="route.name == 'InvestasiDetailKBLI'">
    <InvestasiDetailKBLI/>
  </div>
  <div class="content" v-if="route.name == 'InvestasiDetailResiko'">
    <InvestasiDetailResiko/>
  </div>
  <div class="content" v-if="route.name == 'InvestasiDetailStatus'">
    <InvestasiDetailStatus/>
  </div>
  <div class="content" v-if="route.name == 'InvestasiDetailSektor'">
    <InvestasiDetailSektor/>
  </div>
  <div class="content" v-if="route.name == 'InvestasiDetailPerusahaan'">
    <InvestasiDetailPerusahaan/>
  </div>
  <div class="content" v-if="route.name == 'Perizinan'">
    <Perizinan/>
  </div>
  <div class="content" v-if="route.name == 'Login'">
    <Login/>
  </div>
  <div class="content" v-if="route.name == 'KontakKami'">
    <KontakKami/>
  </div>
  <div class="content" v-if="route.name == 'DaftarRegulasi'">
    <DaftarRegulasi/>
  </div>
  <div class="content" v-if="route.name == 'DaftarDaerahIrigasi'">
    <DaftarDaerahIrigasi/>
  </div>
  <div class="content" v-if="route.name == 'GIS'">
    <GIS/>
  </div>
  <div class="content" v-if="route.name == 'DaftarIP3A'">
    <DaftarIP3A/>
  </div>
  <div class="content" v-if="route.name == 'DaftarGP3A'">
    <DaftarGP3A/>
  </div>
  <div class="content" v-if="route.name == 'DaftarP3A'">
    <DaftarP3A/>
  </div>
  <div class="content" v-if="route.name == 'DaftarTPM'">
    <DaftarTPM/>
  </div>
  <div class="content" v-if="route.name == 'DaftarUPI'">
    <DaftarUPI/>
  </div>
  <div class="content" v-if="route.name == 'DaftarPTGA'">
    <DaftarPTGA/>
  </div>
  <div class="content" v-if="route.name == 'DaftarOP'">
    <DaftarOP/>
  </div>
  <div class="content" v-if="route.name == 'DaftarKOMIR'">
    <DaftarKOMIR/>
  </div>
  <div class="content" v-if="route.name == 'Ringkasan'">
    <Ringkasan/>
  </div>
  <div class="content" v-if="route.name == 'Dashboard'">
    <Dashboard/>
  </div>
  <div class="content" v-if="route.name == 'ImportExcel'">
    <ImportExcel/>
  </div>
  <div class="content" v-if="route.name == 'Slider'">
    <Slider/>
  </div>
  <div class="content" v-if="route.name == 'ProsesSlider'">
    <ProsesSlider/>
  </div>
  <div class="content" v-if="route.name == 'P3A'">
    <P3A/>
  </div>
  <div class="content" v-if="route.name == 'ProsesP3A'">
    <ProsesP3A/>
  </div>
  <div class="content" v-if="route.name == 'MappingP3A'">
    <MappingP3A/>
  </div>
  <div class="content" v-if="route.name == 'Regulasi'">
    <Regulasi/>
  </div>
  <div class="content" v-if="route.name == 'ProsesRegulasi'">
    <ProsesRegulasi/>
  </div>
  <div class="content" v-if="route.name == 'InfoBeranda'">
    <InfoBeranda/>
  </div>
  <div class="content" v-if="route.name == 'ProsesInfoBeranda'">
    <ProsesInfoBeranda/>
  </div>
  <div class="content" v-if="route.name == 'DaerahIrigasi'">
    <DaerahIrigasi/>
  </div>
  <div class="content" v-if="route.name == 'ProsesDaerahIrigasi'">
    <ProsesDaerahIrigasi/>
  </div>
  <div class="content" v-if="route.name == 'KategoriData'">
    <KategoriData/>
  </div>
  <div class="content" v-if="route.name == 'ProsesKategoriData'">
    <ProsesKategoriData/>
  </div>
  <div class="content" v-if="route.name == 'APIPeta'">
    <APIPeta/>
  </div>
  <div class="content" v-if="route.name == 'ProsesAPIPeta'">
    <ProsesAPIPeta/>
  </div>
  <div class="content" v-if="route.name == 'LayerPeta'">
    <LayerPeta/>
  </div>
  <div class="content" v-if="route.name == 'ProsesLayerPeta'">
    <ProsesLayerPeta/>
  </div>
</template>

<script>
import Beranda from './components/views/Publik/Beranda.vue';
import Login from './components/views/Publik/Login.vue';
import KontakKami from './components/views/Publik/KontakKami.vue';
import DaftarRegulasi from './components/views/Publik/DaftarRegulasi.vue';
import DaftarDaerahIrigasi from './components/views/Publik/DaftarDaerahIrigasi.vue';
import GIS from './components/views/Publik/GIS.vue';
import DaftarIP3A from './components/views/Publik/DaftarIP3A.vue';
import DaftarGP3A from './components/views/Publik/DaftarGP3A.vue';
import DaftarP3A from './components/views/Publik/DaftarP3A.vue';
import DaftarTPM from './components/views/Publik/DaftarTPM.vue';
import DaftarUPI from './components/views/Publik/DaftarUPI.vue';
import DaftarPTGA from './components/views/Publik/DaftarPTGA.vue';
import DaftarOP from './components/views/Publik/DaftarOP.vue';
import DaftarKOMIR from './components/views/Publik/DaftarKOMIR.vue';
import Ringkasan from './components/views/Publik/Ringkasan.vue';
import Dashboard from './components/views/Dashboard/Dashboard.vue';
import ImportExcel from './components/views/ImportExcel/ImportExcel.vue';
import Slider from './components/views/Backoffice/Slider/Slider.vue';
import ProsesSlider from './components/views/Backoffice/Slider/ProsesSlider.vue';
import Regulasi from './components/views/Backoffice/Regulasi/Regulasi.vue';
import ProsesRegulasi from './components/views/Backoffice/Regulasi/ProsesRegulasi.vue';
import InfoBeranda from './components/views/Backoffice/InfoBeranda/InfoBeranda.vue';
import ProsesInfoBeranda from './components/views/Backoffice/InfoBeranda/ProsesInfoBeranda.vue';
import DaerahIrigasi from './components/views/Backoffice/DaerahIrigasi/DaerahIrigasi.vue';
import ProsesDaerahIrigasi from './components/views/Backoffice/DaerahIrigasi/ProsesDaerahIrigasi.vue';
import P3A from './components/views/Backoffice/P3A/P3A.vue';
import ProsesP3A from './components/views/Backoffice/P3A/ProsesP3A.vue';
import MappingP3A from './components/views/Backoffice/P3A/MappingP3A.vue';
import KategoriData from './components/views/Backoffice/KategoriData/KategoriData.vue';
import ProsesKategoriData from './components/views/Backoffice/KategoriData/ProsesKategoriData.vue';
import APIPeta from './components/views/Backoffice/APIPeta/APIPeta.vue';
import ProsesAPIPeta from './components/views/Backoffice/APIPeta/ProsesAPIPeta.vue';
import LayerPeta from './components/views/Backoffice/LayerPeta/LayerPeta.vue';
import ProsesLayerPeta from './components/views/Backoffice/LayerPeta/ProsesLayerPeta.vue';

import { useRoute } from 'vue-router';
import { watch } from 'vue';

export default {
  name: 'App',
  components: {
    Beranda,Login,KontakKami,DaftarRegulasi,DaftarDaerahIrigasi,GIS,DaftarIP3A,Dashboard
    ,Slider,ProsesSlider,Regulasi,ProsesRegulasi,InfoBeranda,ProsesInfoBeranda
    ,DaerahIrigasi,ProsesDaerahIrigasi,ImportExcel,P3A,ProsesP3A,MappingP3A
    ,KategoriData,ProsesKategoriData,LayerPeta,ProsesLayerPeta
    ,APIPeta,ProsesAPIPeta,DaftarGP3A,DaftarP3A,DaftarTPM,DaftarUPI,DaftarPTGA
    ,DaftarOP,DaftarKOMIR,Ringkasan
  },
  mounted() {
    document.title = "PORTAL KELEMBAGAAN SDA";
  },
  setup() {
    const route = useRoute()
    watch(() => route.name, () => {
    });

    return { route };
  }
}
</script>

<style></style>
